import React from "react";
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import NoDataFound from "../NoDataFound/NoDataFound";
import ModelSubscriptionLoader from "../Handlers/Loaders/ModelSubscriptionLoader";
import { createNotification } from "react-redux-notify";
import { connect } from "react-redux";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/Helper/NotificationMessage";

const ModelSubscriptionSec = (props) => {
  const handleLoginStatus = (event) => {
    const notificationMessage = getErrorNotificationMessage(
      "Login to Continue"
    );
    props.dispatch(createNotification(notificationMessage));
  };

  return (
    <div>
      {props.subscription.loading ? (
        <ModelSubscriptionLoader />
      ) : props.subscription.data.model_subscriptions.length > 0 ? (
        <div className="subscription-list-my-profile">
          <Table className="my-profile-table">
            <tbody>
              {props.subscription.data.model_subscriptions.map(
                (subscription) => (
                  <tr>
                    <td>{subscription.title}</td>
                    <td>{subscription.plan_formatted}</td>
                    <td>
                      {localStorage.getItem("user_unique_id") ? (
                        <Link
                          to={{
                            pathname: `/request-booking/${props.modelDetails.data.user.username}/${subscription.user_subscription_id}`,
                            model: props.modelDetails.data.user,
                            subscription: subscription,
                          }}
                        >
                          <Button className="btn-request">
                            Request {subscription.amount_formatted}
                          </Button>
                        </Link>
                      ) : (
                        <Link to="#" onClick={handleLoginStatus}>
                          <Button className="btn-request">
                            Request {subscription.amount_formatted}
                          </Button>
                        </Link>
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(null, mapDispatchToProps)(ModelSubscriptionSec);
