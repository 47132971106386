import React, { Component } from "react";
// import HeaderIndex from "./Header/HeaderIndex";
import AuthHeaderIndex from "./Header/AuthHeaderIndex";
import { Notify } from "react-redux-notify";
import FooterIndex from "./Footer/FooterIndex";

class MainLayout extends Component {
  state = {};
  render() {
    return (
      <div className="">
        <Notify position="TopRight" />
        {/* <HeaderIndex /> */}
        <AuthHeaderIndex />
        {React.cloneElement(this.props.children)}
        <FooterIndex />
      </div>
    );
  }
}

export default MainLayout;
