import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import Background from "./profile-cover.jpg";
import ProfileLoader from "../Handlers/Loaders/ProfileLoader";
import "./Account.css";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Profile = (props) => {
  useEffect(() => {
    if (props.profile.loading) props.dispatch(fetchUserDetailsStart());
  }, []);
  return (
    <>
      {props.profile.loading ? (
        <ProfileLoader></ProfileLoader>
      ) : (
        <>
          <div
            className="profile-bg-sec"
            style={{
              background: `url(${Background})`,
            }}
          >
            <div className="container">
              <div className="profile-banner-content">
                <div className="row">
                  <div className="col-lg-7 col-md-10">
                    <h1 className="display-4 text-white">
                      Hello {props.profile.data.name}
                    </h1>
                    <p className="text-white mt-0 mb-5">
                      This is your profile page. You can see the progress you've
                      made with your work and manage your projects or assigned
                      tasks
                    </p>
                    <Link
                      to={`/edit-profile`}
                      className="theme-btn theme-btn-small"
                    >
                      {t("edit_profile")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="profile-sec">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="form-box">
                    <div className="form-title-wrap">
                      <h3 className="title">
                        <i className="la la-user mr-2 text-gray"></i>
                        {t("my_account")}
                      </h3>
                    </div>
                    <div className="form-content">
                      <div className="row">
                        <div className="col-lg-12">
                          <h6 className="heading-small text-muted mb-4">
                            {t("basic_information")}
                          </h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <ul className="list-items list-items-2 flex-grow-1">
                            <li>
                              <span>{t("name")} :</span>
                              {props.profile.data.name}
                            </li>
                            <li>
                              <span>{t("first_name")} :</span>
                              {props.profile.data.first_name}
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <ul className="list-items list-items-2 flex-grow-1">
                            <li>
                              <span>{t("email_address")} :</span>
                              {props.profile.data.email}
                            </li>
                            <li>
                              <span>{t("last_name")} :</span>
                              {props.profile.data.last_name}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="row">
                        <div className="col-lg-12">
                          <h6 className="heading-small text-muted mb-4">
                            {t("other_information")}
                          </h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <ul className="list-items list-items-2 flex-grow-1">
                            <li>
                              <span>{t("mobile")} :</span>
                              {props.profile.data.mobile}
                            </li>
                          </ul>
                        </div>

                        <div className="col-md-12">
                          <ul className="list-items list-items-2 flex-grow-1">
                            <li>
                              <span>{t("about")}:</span>
                              <br></br>
                              {props.profile.data.description
                                ? props.profile.data.description
                                : t("n_a")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-box padding-content">
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className="card-profile-img">
                          <a href="#">
                            <img
                              src="assets/images/placeholder-profile.jpg"
                              alt="no image"
                            />
                          </a>
                        </div>
                        <div className="card-profile-details">
                          <h3 className="title text-muted">
                            {props.profile.data.name}
                          </h3>
                          <h3 className="title text-muted">
                            {props.profile.data.mobile}
                          </h3>
                          <h3 className="title text-muted">
                            {props.profile.data.email}
                          </h3>
                          <Link
                            to={`/settings`}
                            className="theme-btn theme-btn-small"
                          >
                            {t("settings")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Profile);
