import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./PaymentSuccess.css";

const PaymentSuccessIndex = (props) => {
  return (
    <>
      <div className="payment-success-sec">
        <Container>
          <Row>
            <Col md={12} className="text-center">
              <div className="payment-success-img-sec">
                <Image
                  src={
                  window.location.origin +
                  "/assets/images/payment-success.svg"
                  }
                  alt="user-image"
                  className="payment-success-img"
                />
              </div>
              <h1 className="payment-title text-success">
                Your payment was Successfull
              </h1>
              <Link to="/">
                <Button className="btn btn-success mt-4 w-200">Go Home</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  cards: state.cards.cardDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PaymentSuccessIndex);
