import React from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Button,
  Badge,
  Modal,
  Media,
  Image,
  Table,
} from "react-bootstrap";
import configuration from "react-global-configuration";

const ModelStaticSec = () => {
  return (
    <div className="single-filter-footer-sec">
      <Row>
        <Col md={12} lg={6} xl={6}>
          <div className="single-filter-about-sec">
            <h4 className="title">
              What is {configuration.get("configData.site_name")}?
            </h4>
            <Row>
              <Col md={4} className="resp-mrg-btm-xs">
                <div className="about-item">
                  <div className="icon-sec">
                    <Image
                      src="assets/images/mobile-icon.svg"
                      alt="user-image"
                      className="about-icons"
                    />
                  </div>
                  <h4 className="desc">Send your request</h4>
                </div>
              </Col>
              <Col md={4} className="resp-mrg-btm-xs">
                <div className="about-item">
                  <div className="icon-sec">
                    <Image
                      src="assets/images/video-icon.svg"
                      alt="user-image"
                      className="about-icons"
                    />
                  </div>
                  <h4 className="desc">Get your video</h4>
                </div>
              </Col>
              <Col md={4}>
                <div className="about-item">
                  <div className="icon-sec">
                    <Image
                      src="assets/images/thumsup.svg"
                      alt="user-image"
                      className="about-icons"
                    />
                  </div>
                  <h4 className="desc">Make their year</h4>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={12} lg={6} xl={6}>
          <div className="single-filter-about-sec">
            <h4 className="title-1">What does a good request look like??</h4>
            <h5 className="sub-title">Tip #1</h5>
            <p className="desc">
              Try to be as specific as possible with your request such as your
              relationship to the {configuration.get("configData.site_name")}{" "}
              recipient, numbers & details. Ex. "tell my BFF Cam congrats on
              graduating from UCLA."
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ModelStaticSec;
