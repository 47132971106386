import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./RequestBookingIndex.css";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Image,
  Table,
  Button,
} from "react-bootstrap";
import { fetchSingleBookingStart } from "../../store/actions/BookingAction";
import VideoPlayer from "react-video-js-player";
import StarRatings from "react-star-ratings";
import ReviewModal from "./ReviewModal";
import RequestViewLoader from "../Handlers/Loaders/RequestViewLoader";

const RequestView = (props) => {
  useEffect(() => {
    props.dispatch(
      fetchSingleBookingStart({
        booking_unique_id: props.match.params.booking_unique_id,
      })
    );
  }, []);

  const [allReviewModal, setAllReviewModal] = useState(false);

  const handleCloseAllReviewModal = () => {
    setAllReviewModal(false);
  };

  const showReview = (event) => {
    event.preventDefault();
    setAllReviewModal(true);
  };

  const download = (e) => {
    console.log(e.target.href);
    fetch(e.target.href, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="main-wrapper request-view-sec">
        <Container>
          {props.singleBooking.loading ? (
            <RequestViewLoader />
          ) : (
            <div className="main-wrapper">
              <div className="request-view-header-btn-sec">
              {props.singleBooking.data.booking.file ? (
                <div className="upload-doc-btm-sec">
                  <Button
                    className="theme-btn mrng-right-btn"
                    href={props.singleBooking.data.booking.file}
                    download
                    onClick={download}
                  >
                    Download
                  </Button>
                </div>
              ) : (
                <Button className="theme-btn mrng-right-btn">
                  {props.singleBooking.data.booking.booking_status}
                </Button>
              )}

              {props.singleBooking.data.booking.booking_button_status
                .review_btn_status == 1 &&
              localStorage.getItem("is_model") == 0 ? (
                <div className="upload-doc-btm-sec">
                  <Button
                    className="theme-btn"
                    type="submit"
                    onClick={showReview}
                  >
                    Update Review
                  </Button>
                </div>
              ) : (
                ""
              )}
              </div>
              <div className="request-view-sec">
                <div className="request-view-card">
                  <div className="request-view-profile-sec">
                    <Image
                      src={props.singleBooking.data.booking.model.picture}
                      alt="user-image"
                      className="user-img"
                    />
                    <p className="profile-name">
                      New request for{" "}
                      {props.singleBooking.data.booking.model_name}
                    </p>
                  </div>
                  <div className="request-view-tab-sec">
                    <p className="sub-title">Who is this for?</p>
                    <div className="request-felx">
                      <div className="request-nav-icon-sec">
                        <i className="far fa-user user-icon"></i>
                      </div>
                      <p className="nav-desc">
                        {props.singleBooking.data.booking.type_formatted}
                      </p>
                    </div>
                  </div>
                  <div className="request-view-tab-content-sec">
                    <div className="subscription-list-my-profile">
                      <Table className="my-profile-table">
                        <tbody>
                          <tr>
                            <td>To</td>
                            <td>
                              {props.singleBooking.data.booking.to_username}
                            </td>
                          </tr>
                          <tr>
                            <td>From</td>
                            <td>{props.singleBooking.data.booking.username}</td>
                          </tr>
                          <tr>
                            <td>Pronoun</td>
                            <td>{props.singleBooking.data.booking.pronoun}</td>
                          </tr>
                          <tr>
                            <td>Type</td>
                            <td className="text-capitalize">
                              {props.singleBooking.data.booking
                                .post_display_type
                                ? "Public"
                                : "Private"}
                            </td>
                          </tr>
                          <tr>
                            <td>Amount</td>
                            <td>
                              {props.singleBooking.data.booking
                                .booking_payment &&
                                props.singleBooking.data.booking.booking_payment
                                  .total_formatted}
                            </td>
                          </tr>
                          {props.singleBooking.data.booking.username ==
                          localStorage.getItem("username") ? (
                            <tr>
                              <td>ID</td>
                              <td>
                                {props.singleBooking.data.booking
                                  .booking_payment &&
                                  props.singleBooking.data.booking
                                    .booking_payment.payment_id}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="occasion-sec">
                    <h4 className="title">Occasion</h4>
                  </div>
                  <div className="request-felx">
                    <div className="request-nav-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/snowflake.svg"
                        }
                        alt="user-image"
                        className="radio-icon"
                      />
                    </div>
                    <p className="nav-desc">
                      {props.singleBooking.data.booking.occasion_name}
                    </p>
                  </div>
                </div>
                <div className="instruction-card">
                  <h3 className="title">Instructions</h3>
                  <div className="instructor-view-sec">
                    <div className="instructor-info">
                      <h3>My instructions</h3>
                      <p>{props.singleBooking.data.booking.instructions}</p>
                    </div>
                    <div className="instructor-info">
                      <h3>My email</h3>
                      <p>{props.singleBooking.data.booking.user.email}</p>
                    </div>
                    <div className="instructor-info">
                      <h3>Text me order updates</h3>
                      <p>
                        {props.singleBooking.data.booking.user.mobile
                          ? props.singleBooking.data.booking.user.mobile
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>

                {props.singleBooking.data.booking.file != "" &&
                props.singleBooking.data.booking.file_type == "video" ? (
                  <div className="instruction-card">
                    <Row>
                      <Col md={12}>
                        <div className="video-preview-sec">
                          <VideoPlayer
                            controls={true}
                            src={props.singleBooking.data.booking.file}
                            // poster="https://d3el26csp1xekx.cloudfront.net/miscellaneous/videos/useCaseOverlayWebthumbnail.jpg"
                            width="100%"
                            height="336px"
                            className="video-size"
                            autoplay={true}
                            hideControls={[
                              "play",
                              "volume",
                              "seekbar",
                              "timer",
                              "playbackrates",
                              "fullscreen",
                            ]}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : props.singleBooking.data.booking.file != "" ? (
                  <div className="instruction-card">
                    <Row>
                      <Col md={12}>
                        <div className="video-preview-sec">
                          <Image
                            src={props.singleBooking.data.booking.file}
                            alt="user-image"
                            className="preview-video-img"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  ""
                )}

                {props.singleBooking.data.booking_review ? (
                  <div className="instruction-card">
                    <div className="request-info mb-3">
                      <h3>Rating & Reviews</h3>
                      <StarRatings
                        rating={
                          props.singleBooking.data.booking_review
                            .ratings_formatted
                        }
                        starRatedColor="rgb(255, 214, 67)"
                        numberOfStars={5}
                        starDimension="20px"
                        starSpacing="0px"
                      />
                    </div>
                    <div className="request-info">
                      {/* <h3>Review</h3> */}
                      <p>{props.singleBooking.data.booking_review.review}</p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </Container>
      </div>
      {props.singleBooking.loading ? (
        "Loading..."
      ) : (
        <ReviewModal
          allReviewModal={allReviewModal}
          setAllReviewModal={setAllReviewModal}
          handleCloseAllReviewModal={handleCloseAllReviewModal}
          singleBooking={props.singleBooking}
        />
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  singleBooking: state.bookings.singleBooking,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(RequestView);
