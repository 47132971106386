import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ImageLoader from "../Helper/ImageLoader";
import HomeFeaturedLoader from "../Handlers/Loaders/HomeFeatureLoader";
import NoDataFound from "../NoDataFound/NoDataFound";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4.8,
  slidesToScroll: 1,
  arrow: true,
  swipeToSlide: true,
  touchMove: true,
  responsive: [
    {
      breakpoint: 1195,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const HomeCategories = (props) => {
  return (
    <>
      <div className="home-categories-sec">
        <Container fluid>
          <Row>
            <Col md={12}>
              <div className="home-categories-header-sec">
                <Link to="/filter-view">
                  <h1 className="slider-header-title">Categories</h1>
                </Link>
                <Link to="/filter-view"></Link>
              </div>
              <div className="slider-categories-sec">
                <Slider {...settings}>
                  {props.category.loading
                    ? <HomeFeaturedLoader/>
                    : props.category.data.categories.length > 0
                    ? props.category.data.categories.map((category) => (
                        <div>
                          <Link to="/filter-view">
                            <div className="categories-item">
                              <Link to="/filter-view">
                                <ImageLoader
                                  image={category.picture}
                                  alt="user-image"
                                  className="categories-img"
                                />
                              </Link>
                              <Link
                                to="/filter-view"
                                className="categories-title"
                              >
                                {category.name}
                              </Link>
                            </div>
                          </Link>
                        </div>
                      ))
                    : <NoDataFound/>}
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HomeCategories);
