import {
  FETCH_SINGLE_MODEL_DETAILS_START,
  FETCH_SINGLE_MODEL_DETAILS_SUCCESS,
  FETCH_SINGLE_MODEL_DETAILS_FAILURE,
  FETCH_MODEL_DETAILS_START,
  FETCH_MODEL_DETAILS_SUCCESS,
  FETCH_MODEL_DETAILS_FAILURE,
  FETCH_MODEL_SUB_START,
  FETCH_MODEL_SUB_SUCCESS,
  FETCH_MODEL_SUB_FAILURE,
  FETCH_MODEL_RATING_START,
  FETCH_MODEL_RATING_SUCCESS,
  FETCH_MODEL_RATING_FAILURE,
  FETCH_MODEL_POSTS_START,
  FETCH_MODEL_POSTS_SUCCESS,
  FETCH_MODEL_POSTS_FAILURE,
  FETCH_MODEL_SINGLE_POST_START,
  FETCH_MODEL_SINGLE_POST_SUCCESS,
  FETCH_MODEL_SINGLE_POST_FAILURE,
  FETCH_MODEL_SUGGESTION_START,
  FETCH_MODEL_SUGGESTION_SUCCESS,
  FETCH_MODEL_SUGGESTION_FAILURE,
  MODEL_CATEGORY_UPDATE_START,
  MODEL_CATEGORY_UPDATE_SUCCESS,
  MODEL_CATEGORY_UPDATE_FAILURE,
  FETCH_HOME_START,
  FETCH_HOME_SUCCESS,
  FETCH_HOME_FAILURE,
  MODEL_FILTERS_START,
  MODEL_FILTERS_SUCCESS,
  MODEL_FILTERS_FAILURE,
  FEATURED_MODELS_START,
  FEATURED_MODELS_SUCCESS,
  FEATURED_MODELS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  singleModel: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  model: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  modelSub: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  modelRating: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  modelPost: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  modelSinglePost: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  modelSuggestion: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  modelCat: {
    data: {},
    loading: false,
    error: false,
    inputData: {},
    buttonDisabled: false,
    buttonLoadingContent: null,
  },
  home: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  modelFilters: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  featuredModel: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
};

const ModelReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SINGLE_MODEL_DETAILS_START:
      return {
        ...state,
        singleModel: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_SINGLE_MODEL_DETAILS_SUCCESS:
      return {
        ...state,
        singleModel: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_SINGLE_MODEL_DETAILS_FAILURE:
      return {
        ...state,
        singleModel: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };

    case FETCH_MODEL_DETAILS_START:
      return {
        ...state,
        model: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_MODEL_DETAILS_SUCCESS:
      return {
        ...state,
        model: {
          data: action.data,
          loading: false,
          error: false,
          inputData: state.model.inputData,
        },
      };
    case FETCH_MODEL_DETAILS_FAILURE:
      return {
        ...state,
        model: {
          data: {},
          loading: true,
          error: action.error,
          inputData: state.model.inputData,
        },
      };

    case FETCH_MODEL_SUB_START:
      return {
        ...state,
        modelSub: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_MODEL_SUB_SUCCESS:
      return {
        ...state,
        modelSub: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_MODEL_SUB_FAILURE:
      return {
        ...state,
        modelSub: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };
    case FETCH_MODEL_RATING_START:
      return {
        ...state,
        modelRating: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_MODEL_RATING_SUCCESS:
      return {
        ...state,
        modelRating: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_MODEL_RATING_FAILURE:
      return {
        ...state,
        modelRating: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };
    case FETCH_MODEL_POSTS_START:
      return {
        ...state,
        modelPost: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_MODEL_POSTS_SUCCESS:
      return {
        ...state,
        modelPost: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_MODEL_POSTS_FAILURE:
      return {
        ...state,
        modelPost: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };
    case FETCH_MODEL_SINGLE_POST_START:
      return {
        ...state,
        modelSinglePost: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_MODEL_SINGLE_POST_SUCCESS:
      return {
        ...state,
        modelSinglePost: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_MODEL_SINGLE_POST_FAILURE:
      return {
        ...state,
        modelSinglePost: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };
    case FETCH_MODEL_SUGGESTION_START:
      return {
        ...state,
        modelSuggestion: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_MODEL_SUGGESTION_SUCCESS:
      return {
        ...state,
        modelSuggestion: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_MODEL_SUGGESTION_FAILURE:
      return {
        ...state,
        modelSuggestion: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };
    case MODEL_CATEGORY_UPDATE_START:
      return {
        ...state,
        modelCat: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisabled: true,
          buttonLoadingContent: "Loading... Please wait",
        },
      };
    case MODEL_CATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        modelCat: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisabled: false,
          buttonLoadingContent: null,
        },
      };
    case MODEL_CATEGORY_UPDATE_FAILURE:
      return {
        ...state,
        modelCat: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          buttonDisabled: false,
          buttonLoadingContent: null,
        },
      };
    case FETCH_HOME_START:
      return {
        ...state,
        home: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_HOME_SUCCESS:
      return {
        ...state,
        home: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_HOME_FAILURE:
      return {
        ...state,
        home: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };
    case MODEL_FILTERS_START:
      return {
        ...state,
        modelFilters: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
        model: {
          data: {},
          loading: true,
          error: false,
          inputData: state.model.inputData,
        },
      };
    case MODEL_FILTERS_SUCCESS:
      return {
        ...state,
        modelFilters: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
        model: {
          data: action.data,
          loading: false,
          error: false,
          inputData: state.model.inputData,
        },
      };
    case MODEL_FILTERS_FAILURE:
      return {
        ...state,
        modelFilters: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
        model: {
          data: {},
          loading: true,
          error: action.error,
          inputData: state.model.inputData,
        },
      };
    case FEATURED_MODELS_START:
      return {
        ...state,
        featuredModel: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FEATURED_MODELS_SUCCESS:
      return {
        ...state,
        featuredModel: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FEATURED_MODELS_FAILURE:
      return {
        ...state,
        featuredModel: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };
    default:
      return state;
  }
};

export default ModelReducer;
