import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./BillingAccount.css";
import { addBankAccountStart } from "../../../store/actions/BankAccountAction";

const AddBillingAccount = (props) => {
  const [inputData, setInputData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(addBankAccountStart(inputData));
  };

  return (
    <>
      <div className="main-wrapper">
        <Container>
          <div className="add-bank-card">
            <h3 className="title">Add Billing Account</h3>
            <Form
              className="add-bank-form"
              onSubmit={handleSubmit}
              method="POST"
            >
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Nick Name *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nick Name"
                      value={inputData.nickname}
                      name="nickname"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          nickname: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Account Number *</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Account Number"
                      value={inputData.account_number}
                      name="account_number"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          account_number: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>IFSC Code (Optional)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="IFSC Code"
                      value={inputData.ifsc_code}
                      name="ifsc_code"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          ifsc_code: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Route Number *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Route Number"
                      value={inputData.route_number}
                      name="route_number"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          route_number: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Account Name *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Account Name"
                      value={inputData.account_holder_name}
                      name="account_holder_name"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          account_holder_name: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Bank Name (Optional)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Bank Name"
                      value={inputData.bank_name}
                      name="bank_name"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          bank_name: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Swift Code (Optional)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Swift Code"
                      value={inputData.swift_code}
                      name="swift_code"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          swift_code: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>IBAN Number (Optional)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="IBAN Number"
                      value={inputData.iban_number}
                      name="iban_number"
                      pattern="[0-9]*"
                      onChange={(event) => {
                        setInputData({
                          ...inputData,
                          iban_number: event.currentTarget.value,
                        });
                      }}
                    />
                  </Form.Group>
                  <div className="btn-continue-sec">
                    <Button
                      className="btn-continue"
                      type="submit"
                      disabled={props.bankAccount.buttonDisable}
                    >
                      {props.bankAccount.loadingButtonContent !== null
                        ? props.bankAccount.loadingButtonContent
                        : "Submit"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  bankAccount: state.bankAccount.addBankAccountInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddBillingAccount);
