import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import "./Enroll.css";
import { Button, Form, Image } from "react-bootstrap";
import { modelEnrollStart } from "../../store/actions/UserAction";

const EnrollIndex = (props) => {
  const [inputData, setInputData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(modelEnrollStart(inputData));
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="enroll-sec">
          <div className="enroll-card">
            <h3 className="title">
              Apply to join {configuration.get("configData.site_name")} as
              talent!
            </h3>
            <p className="desc">
              If you have fanbase and want to join{" "}
              {configuration.get("configData.site_name")} as talent, you can
              apply here and we'll be in touch within 72 hours.
            </p>
            <Form className="enroll-form" onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Pepe Sylvia"
                  name="name"
                  value={inputData.name}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      name: event.currentTarget.value,
                    })
                  }
                />
                <Form.Text className="short-desc">Required</Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="you@example.com"
                  name="email"
                  value={inputData.email}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      email: event.currentTarget.value,
                    })
                  }
                />
                <Form.Text className="short-desc">Required</Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Phone number (never shared)*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Mobile number"
                  name="mobile"
                  value={inputData.mobile}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      mobile: event.currentTarget.value,
                    })
                  }
                />
                <Form.Text className="short-desc">Required</Form.Text>
              </Form.Group>
              <Form.Group controlId="pronoun-select">
                <Image
                  src={
                    window.location.origin +
                    "/assets/images/select-arrow-white.svg"
                  }
                  alt="user-image"
                  className="select-arrow"
                />
                <Form.Label>Where can we find you? *</Form.Label>
                <Form.Control
                  as="select"
                  name="platform"
                  value={inputData.platform}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      platform: event.currentTarget.value,
                    })
                  }
                >
                  <option>Select</option>
                  <option value="twitter">Twitter</option>
                  <option value="instagram">Instagram</option>
                  <option value="youtube">YouTube</option>
                  <option value="facebook">Facebook</option>
                  <option value="twitch">Twitch</option>
                  <option value="tiktok">TikTok</option>
                  <option value="others">Others</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Your handle *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="@jimmybuffet"
                  name="platform_username"
                  value={inputData.platform_username}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      platform_username: event.currentTarget.value,
                    })
                  }
                />
                <Form.Text className="short-desc">Required</Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>How many followers do you have? *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="400k"
                  name="platform_followers"
                  value={inputData.platform_followers}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      platform_followers: event.currentTarget.value,
                    })
                  }
                />
                <Form.Text className="short-desc">Required</Form.Text>
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Anything else we should know about you?</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  className="height-auto-form"
                  placeholder="Hey I'm having a tough week with classes and I could use some cheering up!"
                  name="description"
                  value={inputData.description}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      description: event.currentTarget.value,
                    })
                  }
                />
                <Form.Text className="short-desc">
                  Required <span>250</span>
                </Form.Text>
              </Form.Group>
              <div className="text-center">
                <small className="enroll-text">
                  Note: you are not automatically enrolled on{" "}
                  {configuration.get("configData.site_name")}. If you meet the
                  eligibility requirements, a talent representative will contact
                  you within a few days to finish onboarding.
                </small>
              </div>
              <Button
                className="btn-continue btn-block"
                type="submit"
                disabled={props.modelEnroll.buttonDisable}
              >
                {props.modelEnroll.loadingButtonContent != null
                  ? props.modelEnroll.loadingButtonContent
                  : "Submit"}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  modelEnroll: state.users.modelEnroll,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EnrollIndex);
