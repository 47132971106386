import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Auth.css";
import { resetPasswordStart } from "../../store/actions/UserAction";

const $ = window.$;

const ResetPassword = (props) => {

  const [inputData, setInputData] = useState({});

  useEffect(() => {
    setInputData({
        ...inputData,
        reset_token: props.match.params.token,
    })
  }, []);

  const handleForgotPassword = (event) => {
    event.preventDefault();
    props.dispatch(resetPasswordStart(inputData));
  };

  useEffect(() => {}, []);
  return (
    <>
      <div className="main-wrapper">
        <Container>
          <div className="auth-warpper">
            <Row>
              <Col
                md={{ span: 6, offset: 3 }}
                sm={6}
                lg={{ span: 6, offset: 3 }}
                xl={{ span: 4, offset: 4 }}
              >
                <div className="login-box">
                  <h2>Reset Password</h2>
                  <Form
                    className="login-form mt-4"
                    onSubmit={handleForgotPassword}
                    method="post"
                  >
                    <Form.Group controlId="formBasicEmail" className="mb-4">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter your new password"
                        value={inputData.password}
                        name="password"
                        autoComplete="off"
                        onChange={(event) =>
                            setInputData({
                                ...inputData,
                                password: event.currentTarget.value,
                            })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="mb-4">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        id="password_confirmation"
                        type="password"
                        placeholder="Confirm Password"
                        name="password_confirmation"
                        value={inputData.password_confirmation}
                        autoComplete="off"
                        onChange={(event) =>
                            setInputData({
                                ...inputData,
                                password_confirmation: event.currentTarget.value,
                            })
                        }
                      />
                    </Form.Group>
                    <Button
                      className="btn-reset"
                      onClick={handleForgotPassword}
                      className="btn btn-auth btn-lg"
                      disabled={props.resetPassword.buttonDisable}
                    >
                      Reset Password
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  resetPassword: state.users.resetPasswordInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ResetPassword);
