import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Image,
  Modal,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { getBankAccountStart } from "../../store/actions/BankAccountAction";
import { sendWithDrawRequestStart } from "../../store/actions/WithDrawAction";
import NoDataFound from "../NoDataFound/NoDataFound";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/Helper/NotificationMessage";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const WithDrawModelSec = (props) => {
  useEffect(() => {
    if (props.bankAccount.loading) props.dispatch(getBankAccountStart());
  }, []);
  const [inputData, setInputData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      inputData.requested_amount < props.wallet.data.user_withdrawals_min_amount
    ) {
      const notificationMessage = getSuccessNotificationMessage(
        "Please enter min amount"
      );
      props.dispatch(createNotification(notificationMessage));
      return false;
    }
    props.dispatch(sendWithDrawRequestStart(inputData));
    setTimeout(() => {
      if (
        props.withDraw.errorCode == 0 ||
        props.withDraw.errorCode == undefined
      ) {
        props.handleClose();
      }
    }, 1000);
  };

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={props.handleClose}
      className="review-modal withdraw-modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>Withdraw Money</h4>
        </Modal.Title>
        <i className="fas fa-times close-icon" onClick={props.handleClose}></i>
      </Modal.Header>
      <Modal.Body>
        <Form className="withdraw-modal-form" onSubmit={handleSubmit}>
          {props.bankAccount.loading ? (
            "Loading..."
          ) : props.bankAccount.data.billing_accounts.length > 0 ? (
            <Form.Group
              controlId="exampleForm.ControlSelect1"
              className="select-account"
            >
              <Form.Control
                as="select"
                onChange={(event) =>
                  setInputData({
                    ...inputData,
                    user_billing_account_id: event.currentTarget.value,
                  })
                }
              >
                <option>Choose Bank Account</option>
                {props.bankAccount.data.billing_accounts.map((account) => (
                  <option
                    value={account.user_billing_account_id}
                    name="user_billing_account_id"
                  >
                    {account.nickname} - {account.account_number}{" "}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          ) : (
            <p className="withdraw-note">
              <Link to="/add-billing-account" target="_blank">
                Please Add Bank account
              </Link>
            </p>
          )}

          <p className="withdraw-note">
            Min withdraw limit is:{" "}
            {props.wallet.data.user_withdrawals_min_amount_formatted}
          </p>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text>
                <i className="fas fa-dollar-sign"></i>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Amount"
              name="requested_amount"
              type="number"
              min="0"
              step="any"
              value={inputData.requested_amount}
              onChange={(event) =>
                setInputData({
                  ...inputData,
                  requested_amount: event.currentTarget.value,
                })
              }
            />
          </InputGroup>

          {props.bankAccount.loading ? (
            "Loading..."
          ) : props.bankAccount.data.billing_accounts.length > 0 ? (
            <Button
              className="btn-withdraw-modal"
              type="submit"
              disabled={props.withDraw.buttonDisable}
            >
              {props.withDraw.loadingButtonContent != null
                ? props.withDraw.loadingButtonContent
                : "Submit"}
            </Button>
          ) : (
            <Button className="btn-withdraw-modal" disabled>
              Submit
            </Button>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToPros = (state) => ({
  bankAccount: state.bankAccount.bankAccount,
  withDraw: state.withDraw.sendWithDraw,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WithDrawModelSec);
