import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./BillingAccount.css";
import { Link } from "react-router-dom";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import {
  deleteBankAccountStart,
  getBankAccountStart,
  makeDefaultBankAccountStart,
} from "../../../store/actions/BankAccountAction";
import { deleteAccountStart } from "../../../store/actions/UserAction";
import BillingAccountLoader from "../../Handlers/Loaders/BillingAccountLoader";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const BillingAccountsIndex = (props) => {
  useEffect(() => {
    if (props.bankAccount.loading) props.dispatch(getBankAccountStart());
  }, []);

  const makeDefault = (event, user_billing_account_id) => {
    event.preventDefault();
    props.dispatch(
      makeDefaultBankAccountStart({
        user_billing_account_id: user_billing_account_id,
      })
    );
  };

  const deleteAccount = (event, user_billing_account_id) => {
    event.preventDefault();
    props.dispatch(
      deleteBankAccountStart({
        user_billing_account_id: user_billing_account_id,
      })
    );
  };


  const makeDefaultConfirmation = (event, user_billing_account_id) => {
    confirmAlert({
      message: 'Are you sure want to make this as default ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => makeDefault(event, user_billing_account_id)
        },
        {
          label: 'No',
        }
      ]
    });
  };

  const deleteConfirmation = (event, user_billing_account_id) => {
    confirmAlert({
      message: 'Are you sure want to delete this account ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteAccount(event, user_billing_account_id)
        },
        {
          label: 'No',
        }
      ]
    });
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="bank-account-list-header-sec">
          <div className="bank-account-list-flex">
            <h3 className="title">Billing Account</h3>
            <p className="desc">
              You earnings will be created based on the withdraw billing
              accounts. Please add proper information
            </p>
          </div>
          <div className="bank-account-list-btn">
            <Link to="/add-billing-account" className="add-new-account-btn">
              Add New Account
            </Link>
          </div>
        </div>
        <Container>
          <div className="bank-account-list-card">
            <Row>
              <Col md={12}>
                {props.bankAccount.loading
                  ? <BillingAccountLoader/>
                  : props.bankAccount.data.billing_accounts.length > 0
                    ?
                    <Table responsive className="bank-account-list-table">
                      <tbody>
                        {props.bankAccount.data.billing_accounts.map(
                            (account) => (
                              <tr>
                                <td>{account.account_holder_name}</td>
                                <td>{account.account_number}</td>
                                <td className="amount">{account.bank_name}</td>
                                <td>{account.ifsc_code}</td>
                                <td>{account.nickname}</td>
                                <td>{account.swift_code}</td>
                                <td>
                                  {account.is_default === 0 ? (
                                    <Button
                                      type="button"
                                      className="btn-theme-default"
                                      onClick={(event) =>
                                        makeDefaultConfirmation(event,
                                          account.user_billing_account_id)}
                                    >
                                      Make Default
                                    </Button>
                                  ) : null}
                                  <Button
                                    type="button"
                                    className="btn-theme-delete"
                                    onClick={(event) =>
                                      deleteConfirmation(
                                        event,
                                        account.user_billing_account_id
                                      )
                                    }
                                  >
                                    Delete
                                </Button>
                                </td>
                              </tr>
                            )
                          )
                        }
                      </tbody>
                    </Table> : <NoDataFound/>}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  bankAccount: state.bankAccount.bankAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(BillingAccountsIndex);
