import React from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Button,
  Badge,
  Modal,
  Media,
  Image,
} from "react-bootstrap";
import configuration from "react-global-configuration";

const HowItWorksModel = (props) => {
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.howItWorksModal}
      onHide={props.handleCloseHowItWorksModal}
      className="review-modal time-line-modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>
            What happens when I request a{" "}
            {configuration.get("configData.site_name")}?
          </h4>
        </Modal.Title>
        <i
          className="fas fa-times close-icon"
          onClick={props.handleCloseHowItWorksModal}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <div className="howitswork-wrapper">
          <div className="howitswork-row">
            <div className="howitswork-icon">
              <Image
                src={window.location.origin + "/assets/images/calendar.svg"}
                alt="user-image"
                className="time-line-img"
              />
            </div>
            <p className="time-line-desc">
              Your request will be completed within 7 days
            </p>
          </div>
          <div className="howitswork-row">
            <div className="howitswork-icon">
              <Image
                src={window.location.origin + "/assets/images/document.svg"}
                alt="user-image"
                className="time-line-img"
              />
            </div>
            <p className="time-line-desc">
              Your receipt and order updates will be sent to the email provided
              under Delivery Information
            </p>
          </div>
          <div className="howitswork-row">
            <div className="howitswork-icon">
              <Image
                src={window.location.origin + "/assets/images/envelop-pink.svg"}
                alt="user-image"
                className="time-line-img"
              />
            </div>
            <p className="time-line-desc">
              When your request is completed, we’ll email and text you a link to
              view, share, or download your{" "}
              {configuration.get("configData.site_name")}
            </p>
          </div>
          <div className="howitswork-row">
            <div className="howitswork-icon no-after">
              <Image
                src={
                  window.location.origin + "/assets/images/credit-card-pink.svg"
                }
                alt="user-image"
                className="time-line-img"
              />
            </div>
            <p className="time-line-desc">
              If for some reason your video isn’t completed, the hold on your
              card will be removed within 5-7 business days
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HowItWorksModel;
