import React, { Component } from "react";
import { connect } from "react-redux";
import {Row, Col, Breadcrumb, Button, Badge, Modal, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./FilterView.css";

class SingleFilterView extends Component {

    constructor() {
        super();
        this.state = {
            show: false,
            reviewModal: false,
        };
       
    }

    showReviewModal = () => {
        this.setState({ reviewModal: true });
      };
    
      closeReviewModal = () => {
        this.setState({ reviewModal: false });
      };
    


    toggle = () => this.setState((currentState) => ({ show: !currentState.show }));


    render() {

        return (
            <>
                <div className="single-filter-view-sec">
                    <div className="single-filter-view-header-sec">
                        <Breadcrumb>
                            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                            <Breadcrumb.Item>Data</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="btn-action-sec">
                            <Button className="btn-join">Join Fan Club</Button>
                            <Button className="btn-copy"><Image src="assets/images/copy.svg" alt="user-image" className="icons-img" /></Button>
                        </div>
                    </div>
                    <div className="single-filter-view-body-sec">
                        <div className="single-filter-profile-sec">
                            <div className="profile-wrapper">
                                <div className="profile-img-sec">
                                    <Image
                                    src={
                                    window.location.origin +
                                    "/assets/images/profile-img.jpg"
                                    }
                                    alt="user-image" 
                                    className="filter-profile-img" 
                                    />
                                </div>
                            </div>
                            <div className="profile-details-sec">
                                <h2 className="title">Emily Kuroda</h2>
                                <p className="sub-desc">Actress - Gilmore Girls</p>
                                <div className="review-title-sec">
                                    <i className="fas fa-star"></i>
                                    <span className="star-rating-count">5</span>
                                    <span className="review-count">(20)</span>
                                    <Button className="btn-see-all-review" onClick={this.showReviewModal}>See all review</Button>
                                </div>
                                <h4 className="desc">Mrs. Kim in Gilmore Girls, other TV: The Good Doctor, Greys Anatomy, The Resident, Movies: Party Boat, Take the 10, Sensei and upcoming Audrey. Asian, Asian-American, East West Players, Gung Ho --- No endorsements.</h4>
                            </div>
                        </div>
                        <div className="single-filter-review-sec">
                            <div className="rating-left-sec">
                                <ul className="list-unstyled rating-sec">
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <span className="rating-count">5.0</span>
                                </ul>
                                <Link to="#" className="see-all-link">See all 323 reviews</Link>
                            </div>
                            <div className="review-info-sec">
                                <h4 className="title">Reviewed by K***** on January 4th, 2021</h4>
                                <p className="desc">
                                    <span id="display_1">What can I say? Emily delivered in spades! I’ll post a reaction video!spoiler: my wife cried. I ordered the Cameo to wish my wife g...</span>
                                    {this.state.show &&
                                        <span id="display_1">What can I say? Emily delivered in spades! I’ll post a reaction video!spoiler: my wife cried. I ordered the Cameo to wish my wife good luck on her 1st day of nursing school. I thought Mrs. Kim would be firm but loving in her wake up call to my wife to get up for school. I even teared up at all the beautiful words. Emily even held up a photo from our wedding. Cant thank you enough. My wife called me the “MVP” of our marriage. Which I know is 💯 not true because she is the true backbone. But I’ll take the compliment</span>
                                    }
                                    <Link to="#" className="more-link" onClick={this.toggle}> more</Link>
                                </p>
                            </div>
                        </div>
                        <div className="request-btn-sec">
                            <Link to="/request-booking">
                                <Button className="btn-request">Request $75</Button>
                            </Link>
                            <Link to="/text-me">
                                <Button className="btn-chat-action">Chat $75</Button>
                            </Link>
                            <Link to="#">
                                <Button className="btn-how-it-works"><Image src="assets/images/info-icon.svg" alt="user-image" className="info-icon" /> How does it work?</Button>
                            </Link>
                        </div>
                        <div className="video-post-sec">
                            <Row>
                                <Col md={4} className="resp-mrg-btm-xs">
                                    <div className="video-post-item">
                                        <div className="video-post-img-sec">
                                            <Image
                                                src={
                                                window.location.origin +
                                                "/assets/images/video-img-1.jpg"
                                                }
                                                alt="user-image" 
                                                className="video-post-img"
                                            />
                                        </div>
                                        <div className="video-post-bottom-sec">
                                            <div className="video-user-img-sec">
                                                <div className="video-user-top-flex">
                                                    <div className="avatar-img">
                                                        <Image
                                                            src={
                                                            window.location.origin +
                                                            "/assets/images/avatar-img.jpg"
                                                            }
                                                            alt="user-image" 
                                                            className="avatar-img"
                                                        />
                                                    </div>
                                                    <div className="avatar-img-1">
                                                        <span>RH</span>
                                                    </div>
                                                </div>
                                                <div className="play-icon-sec">
                                                    <i class="fas fa-play"></i>
                                                </div>
                                            </div>
                                            <div className="video-tag-sec">
                                                <Badge className="badge-tag">#pepTalk</Badge>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4} className="resp-mrg-btm-xs">
                                    <div className="video-post-item">
                                        <div className="video-post-img-sec">
                                            <Image
                                                src={
                                                window.location.origin +
                                                "/assets/images/video-img-1.jpg"
                                                }
                                                alt="user-image" 
                                                className="video-post-img"
                                            />
                                        </div>
                                        <div className="video-post-bottom-sec">
                                            <div className="video-user-img-sec">
                                                <div className="video-user-top-flex">
                                                    <div className="avatar-img">
                                                        <Image
                                                            src={
                                                            window.location.origin +
                                                            "/assets/images/avatar-img.jpg"
                                                            }
                                                            alt="user-image" 
                                                            className="avatar-img"
                                                        />
                                                    </div>
                                                    <div className="avatar-img-1">
                                                        <span>RH</span>
                                                    </div>
                                                </div>
                                                <div className="play-icon-sec">
                                                    <i class="fas fa-play"></i>
                                                </div>
                                            </div>
                                            <div className="video-tag-sec">
                                                <Badge className="badge-tag">#pepTalk</Badge>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="video-post-item">
                                        <div className="video-post-img-sec">
                                            <Image
                                                src={
                                                window.location.origin +
                                                "/assets/images/video-img-1.jpg"
                                                }
                                                alt="user-image" 
                                                className="video-post-img"
                                            />
                                        </div>
                                        <div className="video-post-bottom-sec">
                                            <div className="video-user-img-sec">
                                                <div className="video-user-top-flex">
                                                    <div className="avatar-img">
                                                        <Image
                                                            src={
                                                            window.location.origin +
                                                            "/assets/images/avatar-img.jpg"
                                                            }
                                                            alt="user-image" 
                                                            className="avatar-img"
                                                        />
                                                    </div>
                                                    <div className="avatar-img-1">
                                                        <span>RH</span>
                                                    </div>
                                                </div>
                                                <div className="play-icon-sec">
                                                    <i class="fas fa-play"></i>
                                                </div>
                                            </div>
                                            <div className="video-tag-sec">
                                                <Badge className="badge-tag">#pepTalk</Badge>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="space-mg"></div>
                            <Row>
                                <Col md={4} className="resp-mrg-btm-xs">
                                    <div className="video-post-item">
                                        <div className="video-post-img-sec">
                                            <Image
                                                src={
                                                window.location.origin +
                                                "/assets/images/video-img-1.jpg"
                                                }
                                                alt="user-image" 
                                                className="video-post-img"
                                            />
                                        </div>
                                        <div className="video-post-bottom-sec">
                                            <div className="video-user-img-sec">
                                                <div className="video-user-top-flex">
                                                    <div className="avatar-img">
                                                        <Image
                                                            src={
                                                            window.location.origin +
                                                            "/assets/images/avatar-img.jpg"
                                                            }
                                                            alt="user-image" 
                                                            className="avatar-img"
                                                        />
                                                    </div>
                                                    <div className="avatar-img-1">
                                                        <span>RH</span>
                                                    </div>
                                                </div>
                                                <div className="play-icon-sec">
                                                    <i class="fas fa-play"></i>
                                                </div>
                                            </div>
                                            <div className="video-tag-sec">
                                                <Badge className="badge-tag">#pepTalk</Badge>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4} className="resp-mrg-btm-xs">
                                    <div className="video-post-item">
                                        <div className="video-post-img-sec">
                                            <Image
                                                src={
                                                window.location.origin +
                                                "/assets/images/video-img-1.jpg"
                                                }
                                                alt="user-image" 
                                                className="video-post-img"
                                            />
                                        </div>
                                        <div className="video-post-bottom-sec">
                                            <div className="video-user-img-sec">
                                                <div className="video-user-top-flex">
                                                    <div className="avatar-img">
                                                        <Image
                                                            src={
                                                            window.location.origin +
                                                            "/assets/images/avatar-img.jpg"
                                                            }
                                                            alt="user-image" 
                                                            className="avatar-img"
                                                        />
                                                    </div>
                                                    <div className="avatar-img-1">
                                                        <span>RH</span>
                                                    </div>
                                                </div>
                                                <div className="play-icon-sec">
                                                    <i class="fas fa-play"></i>
                                                </div>
                                            </div>
                                            <div className="video-tag-sec">
                                                <Badge className="badge-tag">#pepTalk</Badge>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="video-post-item before-overlay">
                                        <div className="video-post-img-sec">
                                            <Image
                                                src={
                                                window.location.origin +
                                                "/assets/images/video-img-1.jpg"
                                                }
                                                alt="user-image" 
                                                className="video-post-img"
                                            />
                                        </div>
                                        <div className="video-last-join-sec">
                                            <h5 className="title">Want more?</h5>
                                            <p className="desc">Join Emily Kuroda's Fan Club to unlock even more content.</p>
                                            <Link to="#">
                                                <Button className="btn-join-secondary">
                                                    Join Fans Club
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="single-filter-review-sec">
                            <div className="rating-left-sec">
                                <p className="see-all-link">Typically responds in</p>
                                <h5 className="desc">
                                    <Image
                                        src={
                                        window.location.origin +
                                        "/assets/images/zap.svg"
                                        }
                                        alt="user-image" 
                                        className="zap-icon"
                                    />
                                    20 hours</h5>                            
                            </div>
                            <div className="review-info-sec">
                                <div className="tag-wrapper-sec">
                                    <span className="tag-info">
                                        <Link to="#">
                                            #Actors
                                        </Link>
                                    </span>
                                    <span className="tag-info">
                                        <Link to="#">
                                            #CW
                                        </Link>
                                    </span>
                                    <span className="tag-info">
                                        <Link to="#">
                                            #Gilmore Girls
                                        </Link>
                                    </span>
                                    <span className="tag-info">
                                        <Link to="#">
                                            #TV
                                        </Link>
                                    </span>
                                    <span className="tag-info">
                                        <Link to="#">
                                            #24 Hour Delivery
                                        </Link>
                                    </span>
                                    <span className="tag-info">
                                        <Link to="#">
                                            #Binge-watching Netflix?
                                        </Link>
                                    </span>
                                    <span className="tag-info">
                                        <Link to="#">
                                            #Childhood Favorites
                                        </Link>
                                    </span>
                                    <span className="tag-info">
                                        <Link to="#">
                                            #Asian American
                                        </Link>
                                    </span>
                                    <span className="tag-info">
                                        <Link to="#">
                                            #1 Day Delivery
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="single-filter-footer-sec">
                            <Row>
                                <Col md={12} lg={6} xl={6}>
                                    <div className="single-filter-about-sec">
                                        <h4 className="title">What is Cameo?</h4>
                                        <Row>
                                            <Col md={4} className="resp-mrg-btm-xs">
                                                <div className="about-item">
                                                    <div className="icon-sec">
                                                    <Image
                                                        src={
                                                        window.location.origin +
                                                        "/assets/images/mobile-icon.svg"
                                                        }
                                                        alt="user-image"
                                                        className="about-icons"
                                                    />
                                                    </div>
                                                    <h4 className="desc">Send your request</h4>
                                                </div>
                                            </Col>
                                            <Col md={4} className="resp-mrg-btm-xs">
                                                <div className="about-item">
                                                    <div className="icon-sec">
                                                    <Image
                                                        src={
                                                        window.location.origin +
                                                        "/assets/images/video-icon.svg"
                                                        }
                                                        alt="user-image"
                                                        className="about-icons"
                                                    />
                                                    </div>
                                                    <h4 className="desc">Get your video</h4>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div className="about-item">
                                                    <div className="icon-sec">
                                                    <Image
                                                        src={
                                                        window.location.origin +
                                                        "/assets/images/thumsup.svg"
                                                        }
                                                        alt="user-image"
                                                        className="about-icons"
                                                    />
                                                    </div>
                                                    <h4 className="desc">Make their year</h4>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col md={12} lg={6} xl={6}>
                                    <div className="single-filter-about-sec">
                                        <h4 className="title-1">What does a good request look like??</h4>
                                        <h5 className="sub-title">Tip #1</h5>
                                        <p className="desc">Try to be as specific as possible with your request such as your
                                        relationship to the Cameo recipient, numbers & details. Ex. "tell my BFF Cam congrats
                                        on graduating from UCLA."</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>


                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.reviewModal} 
                    handleClose={this.closeReviewModal}
                    className="review-modal"
                    >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                           <h4>Latest reviews</h4>    
                         </Modal.Title>
                         <i className="fas fa-times close-icon" onClick={this.closeReviewModal}></i>   
                    </Modal.Header>
                    <Modal.Body style={{'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto'}}>
                        <div className="review-modal-view-sec">
                            <div className="rating-left-sec">
                                <ul className="list-unstyled rating-sec">
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <span className="rating-count">5 stars</span>
                                </ul>
                            </div>
                            <div className="review-info-sec">
                                <h4 className="title">Reviewed by K***** on January 4th, 2021</h4>
                                <p className="desc">"Awesome!!"</p>
                                <p className="sub-desc">Private Cameo Video</p>
                            </div>
                        </div>
                        <div className="review-modal-view-sec">
                            <div className="rating-left-sec">
                                <ul className="list-unstyled rating-sec">
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <span className="rating-count">5 stars</span>
                                </ul>
                            </div>
                            <div className="review-info-sec">
                                <h4 className="title">Reviewed by K***** on January 4th, 2021</h4>
                                <p className="desc">"Awesome!!"</p>
                                <p className="sub-desc">Private Cameo Video</p>
                            </div>
                        </div>
                        <div className="review-modal-view-sec">
                            <div className="rating-left-sec">
                                <ul className="list-unstyled rating-sec">
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <span className="rating-count">5 stars</span>
                                </ul>
                            </div>
                            <div className="review-info-sec">
                                <h4 className="title">Reviewed by K***** on January 4th, 2021</h4>
                                <p className="desc">"Awesome!!"</p>
                                <p className="sub-desc">Private Cameo Video</p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleFilterView);
