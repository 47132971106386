import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Image } from "react-bootstrap";
import "./Settings.css";

const SettingsIndex = (props) => {
  useEffect(() => {}, []);
  return (
    <>
      <div className="main-wrapper">
        <div className="settings-sec">
          <Container>
            <h3 className="main-title">Account Settings</h3>
            <Row>
              <Col md={6} xl={4} lg={4}>
                <Link to={"/profile"}>
                  <div className="settings-card">
                    <div className="settings-icon-sec">
                      <Image
                        src={
                          window.location.origin + "/assets/images/profile.svg"
                        }
                        alt="user-image"
                        className="settings-icon"
                      />
                    </div>
                    <div className="settings-info">
                      <h4 className="title">Profile</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={6} xl={4} lg={4}>
                <Link to="/change-password">
                  <div className="settings-card">
                    <div className="settings-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/change-password.svg"
                        }
                        alt="user-image"
                        className="settings-icon"
                      />
                    </div>
                    <div className="settings-info">
                      <h4 className="title">Change Password</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={6} xl={4} lg={4}>
                <Link to="/delete-account">
                  <div className="settings-card">
                    <div className="settings-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/card-delete-theme.svg"
                        }
                        alt="user-image"
                        className="settings-icon"
                      />
                    </div>
                    <div className="settings-info">
                      <h4 className="title">Delete Account</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={6} xl={4} lg={4}>
                <Link to="/cards">
                  <div className="settings-card">
                    <div className="settings-icon-sec">
                      <Image
                        src={
                          window.location.origin + "/assets/images/add-bank.svg"
                        }
                        alt="Cards"
                        className="settings-icon"
                      />
                    </div>
                    <div className="settings-info">
                      <h4 className="title">Cards Management</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={6} xl={4} lg={4}>
                <Link to="/add-billing-account">
                  <div className="settings-card">
                    <div className="settings-icon-sec">
                      <Image
                        src={
                          window.location.origin + "/assets/images/add-bank.svg"
                        }
                        alt="user-image"
                        className="settings-icon"
                      />
                    </div>
                    <div className="settings-info">
                      <h4 className="title">Add Bank</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={6} xl={4} lg={4} hidden>
                <Link to="/payments">
                  <div className="settings-card">
                    <div className="settings-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/payment-method.svg"
                        }
                        alt="user-image"
                        className="settings-icon"
                      />
                    </div>
                    <div className="settings-info">
                      <h4 className="title">Payments</h4>
                    </div>
                  </div>
                </Link>
              </Col>

              <Col md={6} xl={4} lg={4}>
                <Link to={`/wallet`}>
                  <div className="settings-card">
                    <div className="settings-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/bank-account.svg"
                        }
                        alt="user-image"
                        className="settings-icon"
                      />
                    </div>
                    <div className="settings-info">
                      <h4 className="title">Wallet</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={6} xl={4} lg={4}>
                <Link to={`/billing-accounts`}>
                  <div className="settings-card">
                    <div className="settings-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/bank-account.svg"
                        }
                        alt="user-image"
                        className="settings-icon"
                      />
                    </div>
                    <div className="settings-info">
                      <h4 className="title">Bank Accounts</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={6} xl={4} lg={4}>
                <Link to={`/requests`}>
                  <div className="settings-card">
                    <div className="settings-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/request-details.svg"
                        }
                        alt="user-image"
                        className="settings-icon"
                      />
                    </div>
                    <div className="settings-info">
                      <h4 className="title">Requests Management</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              {localStorage.getItem("is_model") == 2 ? (
                <>
                  <Col md={6} xl={4} lg={4}>
                    <Link to={`/subscription-list`}>
                      <div className="settings-card">
                        <div className="settings-icon-sec">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/images/subscription.svg"
                            }
                            alt="user-image"
                            className="settings-icon"
                          />
                        </div>
                        <div className="settings-info">
                          <h4 className="title">Subscriptions</h4>
                        </div>
                      </div>
                    </Link>
                  </Col>
                  <Col md={6} xl={4} lg={4}>
                    <Link to={`/kyc-documents`}>
                      <div className="settings-card">
                        <div className="settings-icon-sec">
                          <Image
                            src={
                              window.location.origin +
                              "/assets/images/subscription.svg"
                            }
                            alt="user-image"
                            className="settings-icon"
                          />
                        </div>
                        <div className="settings-info">
                          <h4 className="title">Kyc Documents</h4>
                        </div>
                      </div>
                    </Link>
                  </Col>
                </>
              ) : (
                ""
              )}
            </Row>
            <h3 className="main-title mt-3">Security Settings</h3>
            <Row>
              <Col md={6} xl={4} lg={4} hidden>
                <div className="settings-card-1">
                  <div className="flex-toggle">
                    <h6 className="head">Notification Preferances</h6>
                    <Form>
                      <Form.Check type="switch" id="custom-switch" label="" />
                    </Form>
                  </div>
                  <p className="short-note mt-3 mb-0">
                    Get push notification to find out what's going on when
                    you're not on Ledsend. You Can trun them off anytime.
                  </p>
                </div>
              </Col>
              <Col md={6} xl={4} lg={4}>
                <Link to="/page/help">
                  <div className="settings-card">
                    <div className="settings-icon-sec">
                      <Image
                        src={window.location.origin + "/assets/images/help.svg"}
                        alt="user-image"
                        className="settings-icon"
                      />
                    </div>
                    <div className="settings-info">
                      <h4 className="title">Help & Support</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={6} xl={4} lg={4}>
                <Link to="/page/privacy">
                  <div className="settings-card">
                    <div className="settings-icon-sec">
                      <Image
                        src={
                          window.location.origin + "/assets/images/privacy.svg"
                        }
                        alt="user-image"
                        className="settings-icon"
                      />
                    </div>
                    <div className="settings-info">
                      <h4 className="title">Privacy & Saftey</h4>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={6} xl={4} lg={4}>
                <Link to="/page/terms">
                  <div className="settings-card">
                    <div className="settings-icon-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/terms-condition.svg"
                        }
                        alt="user-image"
                        className="settings-icon"
                      />
                    </div>
                    <div className="settings-info">
                      <h4 className="title">Terms & Conditions</h4>
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SettingsIndex);
