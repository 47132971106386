import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHighlighter,
  faInbox,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Navbar,
  Nav,
  InputGroup,
  FormControl,
  Image,
  Media,
  Dropdown,
  Badge,
} from "react-bootstrap";
import { modelSearchStart } from "../../../store/actions/UserAction";

const AuthHeaderIndex = (props) => {
  useEffect(() => {}, []);

  const [show, toggleShow] = useState(false);

  const handleSearch = (event) => {
    if (event.currentTarget.value === "") {
      toggleShow(false);
    } else {
      toggleShow(true);
      props.dispatch(modelSearchStart({ key: event.currentTarget.value }));
    }
  };

  return (
    <>
      <Navbar className="header-nav" expand="lg">
        <Navbar.Brand>
          <Link to="/">
            <Image
              src={configuration.get("configData.site_logo")}
              alt={configuration.get("configData.site_name")}
              className="logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <InputGroup className="search-header-bar">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  {" "}
                  <FontAwesomeIcon icon={faSearch} color="gray" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Search..."
                aria-label="Username"
                aria-describedby="basic-addon1"
                name="key"
                onChange={handleSearch}
                autoComplete="off"
              />
            </InputGroup>
            {show && (
              <div className="search-suggestion-sec">
                <ul className="list-unstyled search-dropdown-list-sec">
                  {props.modelSearch.loading ? (
                    "Loading..."
                  ) : props.modelSearch.data.users.length > 0 ? (
                    props.modelSearch.data.users.map((user) => (
                      <Media as="li">
                        <Link
                          to={`/${user.username}`}
                          onClick={() => toggleShow(false)}
                        >
                          <div className="search-body">
                            <div className="user-img-sec">
                              <Image
                                alt="#"
                                src={user.picture}
                                className="user-img"
                              />
                            </div>
                            <div className="search-content">
                              <h5 className="title">{user.name}</h5>
                              <p className="text-muted">
                                {user.category_name} - {user.subcategory_name}
                              </p>
                            </div>
                          </div>
                        </Link>
                      </Media>
                    ))
                  ) : (
                    <div className="search-no-result-sec">
                      <p>No search result found</p>
                      <div className="no-result-search-header">
                        <div className="no-result-search-icon">
                          <i className="fas fa-search"></i>
                        </div>
                        <div className="no-result-search-info">
                          <p>"Aravinth234"</p>
                        </div>
                      </div>
                    </div>
                  )}
                </ul>
              </div>
            )}
            {localStorage.getItem("user_unique_id") ? (
              <Dropdown>
                <Dropdown.Toggle
                  className="header-dropdown"
                  id="dropdown-basic"
                >
                  <div className="user-dropdown-img-sec">
                    <Image
                      src={localStorage.getItem("user_picture")}
                      alt={localStorage.getItem("username")}
                      className="user-img"
                    />
                  </div>
                  <i className="fas fa-chevron-down header-drop-icon"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="header-dropdown-menu">
                  <ul className="list-unstyled header-drop-down-item">
                    <Media as="li">
                      <Link to={`/profile`}>Account</Link>
                    </Media>
                    <Media as="li">
                      <Link to={`/followings`}>Following</Link>
                    </Media>
                    <Media as="li">
                      <Link to={`/settings`}>Settings</Link>
                    </Media>
                    <Media as="li" className="hidden">
                      <Link to="/invite">Invite friends</Link>
                      <Badge className="amount-badge-1">Get $5</Badge>
                    </Media>
                    <Media as="li">
                      <Link to={`/logout`}>Logout</Link>
                    </Media>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Link to="/login" className="nav-link">
                Login
              </Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

const mapStateToPros = (state) => ({
  modelSearch: state.users.modelSearch,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AuthHeaderIndex);
