import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Account.css";
import { changePasswordStart } from "../../store/actions/UserAction";

const ChangePassword = (props) => {
  const [inputData, setInputData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(changePasswordStart(inputData));
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="change-password-sec">
          <div className="change-password-card">
            <h3 className="title">Change Password</h3>
            <Form className="change-password-form" onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>OLD PASSWORD</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your old password"
                  name="old_password"
                  value={inputData.old_password}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      old_password: event.currentTarget.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>NEW PASSWORD</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your new password"
                  name="password"
                  value={inputData.password}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      password: event.currentTarget.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>CONFIRM PASSWORD</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  name="password_confirmation"
                  value={inputData.password_confirmation}
                  onChange={(event) =>
                    setInputData({
                      ...inputData,
                      password_confirmation: event.currentTarget.value,
                    })
                  }
                />
              </Form.Group>
              <Button
                className="btn-change-password"
                disabled={props.changePassword.buttonDisable}
                onClick={handleSubmit}
              >
                {props.changePassword.loadingButtonContent != null
                  ? props.changePassword.loadingButtonContent
                  : "Change Password"}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  changePassword: state.users.changePassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ChangePassword);
