import React, { useState, useEffect, Component } from "react";
import { connect } from "react-redux";
import "./Invite.css";
import { Container, Row, Col, Button, Form, InputGroup, FormControl, Image } from "react-bootstrap";

const InvitePageIndex = (props) => {
    useEffect(() => { }, []);
    return (
        <>
            <div className="main-wrapper invite-wrapper">
                <div className="invite-wrapper-sec">
                <div className="invite-sec">
                    <div className="invite-card">
                        <Image
                            src={
                            window.location.origin +
                            "/assets/images/celecloud.svg"
                            }
                            alt="no image" 
                            className="coin-img"
                        />
                        <h1 className="title">
                            Share your code.
                            <br></br>
                            Get <span>$5.</span>
                        </h1>
                        <p className="desc">Want a discount on your next Cameo? Tell your friends about Cameo. 
                        We'll drop $5 in their account and when they buy a Cameo video priced at $10 or more you'll get $5 too.
                        </p>
                        <Form>
                            <InputGroup className="">
                                <FormControl
                                placeholder="cameo.com/join/7slmsnwp"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                />
                                <InputGroup.Append>
                                <InputGroup.Text id="basic-addon2">copy</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form>
                    </div>
                    <div className="invite-card">
                        <Image src="assets/images/envelop-white.svg" alt="no image" className="message-icon"/>
                        <p className="sub-desc">Or, if you're feeling lazy, enter your friend's email and we'll send them a note.</p>
                        <Form className="email-add-form">
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="beno@example.com" />
                            </Form.Group>
                            <Button className="btn-add-email">
                                <Image
                                    src={
                                    window.location.origin +
                                    "/assets/images/circle-plus.svg"
                                    }
                                    alt="no image" 
                                    className="circle-plus"
                                />
                                Add another email
                            </Button>
                            <Button className="btn-send-email btn-block btn-lg" type="submit">Send emails</Button>
                            <p className="short-note">Restrictions apply. See <a href="#">Terms</a>.</p>
                        </Form>
                    </div>
                </div>
                </div>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(InvitePageIndex);
