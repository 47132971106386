import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Media } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchCategoryStart } from "../../store/actions/LookUpAction";

const HomeCategory = (props) => {
  return (
    <>
      <div className="header-filter-scroll">
        <ul className="list-unstyled fliter-scroll-sec">
          <Media as="li">
            <Link to="/">Home</Link>
          </Media>
          <Media as="li">
            <Link
              to={{
                pathname: `/browse/featured`,
                type: "category",
              }}
            >
              Featured
              {/* <span>633</span> */}
            </Link>
          </Media>

          {props.type === "category"
            ? props.category.loading
              ? "Loading..."
              : props.category.data.categories.length > 0
              ? props.category.data.categories.map((category) => (
                  <Media as="li">
                    <Link
                      to={{
                        pathname: `/browse/${category.category_unique_id}`,
                        type: "category",
                      }}
                    >
                      {category.name} ({category.total_users})
                    </Link>
                  </Media>
                ))
              : ""
            : null}
          {props.type === "subcategory"
            ? props.category.loading
              ? "Loading..."
              : props.category.data.sub_categories.length > 0
              ? props.category.data.sub_categories.map((category) => (
                  <Media as="li">
                    <Link
                      to={{
                        pathname: `/browse/${category.sub_category_unique_id}`,
                        type: "subcategory",
                      }}
                    >
                      {category.name}  ({category.total_users ? category.total_users : 0})
                    </Link>
                  </Media>
                ))
              : "No data fouund"
            : null}
        </ul>
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(HomeCategory);
