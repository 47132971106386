import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import "./Enroll.css";
import { Link } from "react-router-dom";

const EnrollSuccess = (props) => {
  return (
    <>
      <div className="main-wrapper">
        <div className="enroll-sec">
          <div className="enroll-card">
            <h3 className="title">Your enrollment submitted.</h3>
            <p className="desc">
              The application approval process will take 2 - 3 days time. Please
              stay with us.
            </p>
            <div className="text-center mt-3">
              <Link to={`/`} className="btn-email btn btn-primary">
                Go Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EnrollSuccess);
