import React, { useState, useEffect, Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import "./Dashboard.css";
import { Container, Row, Col, Image, Table } from "react-bootstrap";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import ImageLoader from "../Helper/ImageLoader";
import DashboardLoader from "../Handlers/Loaders/DashboardLoader";

const DashboardIndex = (props) => {
  useEffect(() => {
    if (props.profile.loading) props.dispatch(fetchUserDetailsStart());
  }, []);

  return (
    <>
      <div className="main-wrapper dashboard-wrapper">
        {props.profile.loading ? (
          <DashboardLoader />
        ) : (
          <div className="dashboard-sec">
            <Container>
              <Row>
                <Col md={4}>
                  <div className="profile-img-sec">
                    <ImageLoader
                      image={props.profile.data.picture}
                      className="user-img"
                    />
                  </div>
                  <div className="edit-avatar-sec">
                    <Link to="/edit-profile">edit avatar</Link>
                  </div>
                </Col>
                <Col md={8} hidden>
                  <div className="user-balance-sec">
                    <p className="invite-amount">
                      <Link to="#">Get $5</Link>
                    </p>
                    <p className="invite-desc">
                      Invite friends to join{" "}
                      {configuration.get("configData.site_name")}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col ms={12}>
                  <Table
                    className="table table-striped dashboard-table mt-3"
                    responsive
                  >
                    <tbody>
                      <tr>
                        <th>Your Name</th>
                        <td>
                          <span>
                            <span>
                              <span class="data-dynamic">
                                {props.profile.data.name}
                              </span>
                              <Link to="/edit-profile">
                                <span class="data-static">edit</span>
                              </Link>
                            </span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>Your Username</th>
                        <td>
                          <span>
                            <span>
                              <span class="data-dynamic">
                                @{props.profile.data.username}
                              </span>
                              <Link to="/edit-profile">
                                <span class="data-static">edit</span>
                              </Link>
                            </span>
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <th>Your Email</th>
                        <td>
                          <span>
                            <span>
                              <span class="data-dynamic">
                                {props.profile.data.email}
                              </span>
                              <Link to="/edit-profile">
                                <span class="data-static">edit</span>
                              </Link>
                            </span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>Your Facebook</th>
                        <td>
                          <span>
                            <span>
                              <span class="data-dynamic">
                                {props.profile.data.facebook_link != null ? (
                                  <a
                                    target="_blank"
                                    href={props.profile.data.facebook_link}
                                  >
                                    {props.profile.data.facebook_link}
                                  </a>
                                ) : (
                                  ""
                                )}
                              </span>
                              <Link to="/edit-profile">
                                <span class="data-static">edit</span>
                              </Link>
                            </span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>Your Twitter</th>
                        <td>
                          <span>
                            <span>
                              <span class="data-dynamic">
                                {props.profile.data.twitter_link ? (
                                  <a
                                    target="_blank"
                                    href={props.profile.data.twitter_link}
                                  >
                                    {props.profile.data.twitter_link}
                                  </a>
                                ) : (
                                  ""
                                )}
                              </span>
                              <Link to="/edit-profile">
                                <span class="data-static">edit</span>
                              </Link>
                            </span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>Your Bio</th>
                        <td>
                          <span>
                            <span>
                              <span class="data-dynamic">
                                {props.profile.data.about}
                              </span>
                              <Link to="/edit-profile">
                                <span class="data-static">edit</span>
                              </Link>
                            </span>
                          </span>
                        </td>
                      </tr>

                      <tr hidden>
                        <th>Your Twitter handle</th>
                        <td>
                          <span>
                            <span>
                              <span class="data-dynamic"></span>
                              <Link to="/edit-profile">
                                <span class="data-static">add</span>
                              </Link>
                            </span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>Your Category</th>
                        <td>
                          {props.profile.data.category_name} -{" "}
                          {props.profile.data.subcategory_name}
                          {props.profile.data.is_model ?
                          <Link to="/categories">
                            <span class="data-static">Update Category</span>
                          </Link>
                          : ''}
                        </td>
                      </tr>
                      <tr>
                        <th>Legal</th>
                        <td>
                          <Link to="/page/terms">
                            <span class="data-static">Terms</span>
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DashboardIndex);
