import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageLoader from "../Helper/ImageLoader";
import HomeFeatureLoader from "../Handlers/Loaders/HomeFeatureLoader";
import NoDataFound from "../NoDataFound/NoDataFound";

const CategoryModelSec = (props) => {
  return (
    <>
      <div className="home-featured-sec">
        <Container fluid>
          <Row>
            {props.home.loading ? (
              <HomeFeatureLoader />
            ) : props.home.data.categories.length > 0 ? (
              props.home.data.categories.map((category) =>
                category.models.length > 0 ? (
                  <Col md={12}>
                    <div className="home-featured-header-sec">
                      <Link
                        to={{
                          pathname: `/browse/${category.category_unique_id}`,
                          type: "category",
                        }}
                      >
                        <h1 className="slider-header-title">{category.name}</h1>
                      </Link>
                      <Link
                        to={{
                          pathname: `/browse/${category.category_unique_id}`,
                          type: "category",
                        }}
                      >
                        <p className="slider-header-link">See All</p>
                      </Link>
                    </div>
                    <div className="slider-featured-sec">
                      {category.models.map((model) => (
                        <div>
                          <Link to={"/" + model.username}>
                            <div className="featured-card">
                              <div className="overlay-slider"></div>
                              <ImageLoader
                                image={model.picture}
                                alt={model.name}
                                className="featured-img"
                              />
                              {model.is_featured ? (
                                <span className="flag-icon-img-sec">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/assets/images/featured.svg"
                                    }
                                    alt="user-image"
                                    className="flag-icon-img"
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                              <p className="featured-desc">
                                {model.subscription_amount}
                              </p>
                            </div>
                            <div className="featured-details-sec">
                              <div className="featured-details">
                                <Link
                                  to={"/" + model.username}
                                  className="title"
                                >
                                  {model.name}
                                </Link>
                              </div>
                              <div className="featured-details">
                                <Link
                                  to={"/" + model.username}
                                  className="desc"
                                >
                                  {model.category_name}
                                </Link>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </Col>
                ) : (
                  ""
                )
              )
            ) : null}
          </Row>
        </Container>
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapDispatchToProps)(CategoryModelSec);
