import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./FansFollowing.css";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserCardSec from "./UserCardSec";
import { fetchFollowingStart } from "../../store/actions/FollowAction";
import NoDataFound from "../NoDataFound/NoDataFound";
import FollowingLoader from "../Handlers/Loaders/FollowingLoader";
import useInfiniteScroll from "../Helper/useInfiniteScroll";

const Following = (props) => {
  useEffect(() => {
    if (props.following.loading) props.dispatch(fetchFollowingStart());
  }, []);

  const fetchFollowingData = () => {
    setTimeout(() => {
      if (
        props.following.data.followers &&
        props.following.data.followers.length !== 0
      ) {
        props.dispatch(fetchFollowingStart());
        setIsFetching(false);
      } else {
        setNoMoreData(true);
      }
    }, 3000);
  };

  const [isFetching, setIsFetching] = useInfiniteScroll(fetchFollowingData);

  const [noMoreData, setNoMoreData] = useState(false);
  return (
    <>
      <div className="main-wrapper fan-following">
        <Container>
          <h3 className="heading-title">Following</h3>
          {props.following.loading ? (
            <FollowingLoader />
          ) : props.following.data.followers.length > 0 ? (
            <div className="followers-sec">
              <Row>
                {props.following.data.followers.map((follow) => (
                  <UserCardSec user={follow.otherUser} />
                ))}
              </Row>
              <Row hidden>
                <Col md={12}>
                  <div className="btn-view-more-sec">
                    <Button className="btn-view-more">
                      Load More<i class="fas fa-chevron-right ml-2"></i>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <NoDataFound></NoDataFound>
          )}

          {noMoreData !== true ? (
            <>{isFetching && "Fetching..."}</>
          ) : (
            "No More Data"
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  following: state.follow.following,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Following);
