import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  ADD_BILLING_ACCOUNT_START,
  GET_BILLING_ACCOUNT_START,
  MAKE_DEFAULT_BILLING_ACCOUNT_START,
  DELETE_BILLING_ACCOUNT_START,
  FETCH_SINGLE_BILLING_ACCOUNT_START,
  FETCH_BOOKING_HISTORY_START,
  FETCH_BOOKING_UPCOMING_START,
  FETCH_BOOKING_VIEW_START,
  FETCH_BOOKINGS_START,
  FETCH_SINGLE_BOOKING_START,
  BOOKING_SAVE_START,
  BOOKING_FILE_UPLOAD_START,
  UPDATE_USER_REVIEW_START,
  BOOKING_CANCEL_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/Helper/NotificationMessage";
import {
  bookingCancelFailure,
  bookingCancelSuccess,
  bookingSaveFailure,
  bookingSaveStart,
  bookingSaveSuccess,
  fetchBookingCancelledFailure,
  fetchBookingCancelledSuccess,
  fetchBookingsFailure,
  fetchBookingsSuccess,
  fetchBookingUpcomingFailure,
  fetchBookingUpcomingSuccess,
  fetchSingleBookingFailure,
  fetchSingleBookingSuccess,
  postReviewUpdateSuccess,
  postReviewUpdateFailure,
  fetchBookingsStart
} from "../actions/BookingAction";

function* fetchBookingAPI() {
  try {
    const response = yield api.postMethod("model_bookings_index");

    if (response.data.success) {
      yield put(fetchBookingsSuccess(response.data.data));
    } else {
      yield put(fetchBookingsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchBookingsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleBookingAPI() {
  try {
    const inputData = yield select(
      (state) => state.bookings.singleBooking.inputData
    );
    const response = yield api.postMethod("bookings_view", inputData);

    if (response.data.success) {
      yield put(fetchSingleBookingSuccess(response.data.data));
    } else {
      yield put(fetchSingleBookingFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleBookingFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* saveBookingAPI() {
  try {
    const inputData = yield select(
      (state) => state.bookings.saveBooking.inputData
    );
    const response = yield api.postMethod("bookings_save", inputData);

    if (response.data.success) {
      yield put(bookingSaveSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/requests");
    } else {
      yield put(bookingSaveFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(bookingSaveFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* updatePostReviewAPI() {
  try {
    const inputData = yield select(
      (state) => state.bookings.postReviews.inputData
    );
    const response = yield api.postMethod("booking_rating_save", inputData);

    if (response.data.success) {
      yield put(bookingSaveSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/requests");
    } else {
      yield put(bookingSaveFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(bookingSaveFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* bookingCancelAPI() {
  try {
    const inputData = yield select(
      (state) => state.bookings.bookingCancel.inputData
    );
    const response = yield api.postMethod("bookings_cancel", inputData);

    if (response.data.success) {
      yield put(bookingCancelSuccess(response.data));
      
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchBookingsStart());

    } else {
      yield put(bookingCancelFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(bookingCancelFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* uploadBookingVideoAPI() {
  try {
    const inputData = yield select(
      (state) => state.bookings.uploading.inputData
    );
    const response = yield api.postMethod("bookings_file_upload", inputData);

    if (response.data.success) {
      yield put(bookingSaveSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/requests");
    } else {
      yield put(bookingSaveFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(bookingSaveFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_BOOKINGS_START, fetchBookingAPI)]);
  yield all([
    yield takeLatest(FETCH_SINGLE_BOOKING_START, fetchSingleBookingAPI),
  ]);
  yield all([yield takeLatest(BOOKING_SAVE_START, saveBookingAPI)]);
  yield all([
    yield takeLatest(BOOKING_FILE_UPLOAD_START, uploadBookingVideoAPI),
  ]);
  yield all([
    yield takeLatest(UPDATE_USER_REVIEW_START, updatePostReviewAPI),
  ]);
  yield all([
    yield takeLatest(BOOKING_CANCEL_START, bookingCancelAPI),
  ]);
}
