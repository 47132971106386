import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Image,
  Modal,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "./Wallet.css";
import {
  fetchWalletDetailsStart,
  walletHistoryStart,
} from "../../../store/actions/WalletAction";
import WithDrawModelSec from "../../Helper/WithDrawModelSec";
import WalletLoader from "../../Handlers/Loaders/WalletLoader";
import NoDataFound from "../../NoDataFound/NoDataFound";

const WalletIndex = (props) => {
  useEffect(() => {
    if (props.wallet.loading) props.dispatch(fetchWalletDetailsStart());
    if (props.walletHistory.loading) props.dispatch(walletHistoryStart());
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="main-wrapper">
        <div className="wallet-sec">
          {props.wallet.loading ? (
            <WalletLoader />
          ) : (
            <Container>
              <Row>
                <Col md={12}>
                  <div className="btn-withdraw-sec">
                    <Button className="btn-withdraw" onClick={handleShow}>
                      Withdraw Money
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4} className="resp-mrg-btm-xs">
                  <div className="wallet-card">
                    <div className="wallet-icon-sec">
                      <Image
                        src={
                          window.location.origin + "/assets/images/wallet.svg"
                        }
                        alt="user-image"
                        className="wallet-icon-img"
                      />
                    </div>
                    <div className="wallet-info-sec">
                      <h4 className="title">
                        {props.wallet.data.user_wallet.total_formatted}
                      </h4>
                      <p className="desc">Total</p>
                    </div>
                  </div>
                </Col>
                <Col md={4} className="resp-mrg-btm-xs">
                  <div className="wallet-card">
                    <div className="wallet-icon-sec">
                      <Image
                        src={window.location.origin + "/assets/images/hand.svg"}
                        alt="user-image"
                        className="wallet-icon-img"
                      />
                    </div>
                    <div className="wallet-info-sec">
                      <h4 className="title">
                        {props.wallet.data.user_wallet.remaining_formatted}
                      </h4>
                      <p className="desc">Remaining</p>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="wallet-card">
                    <div className="wallet-icon-sec">
                      <Image
                        src={window.location.origin + "/assets/images/drop.svg"}
                        alt="user-image"
                        className="wallet-icon-img"
                      />
                    </div>
                    <div className="wallet-info-sec">
                      <h4 className="title">
                        {props.wallet.data.user_wallet.used_formatted}
                      </h4>
                      <p className="desc">Used</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="resp-mrg-btm-xs">
                  {props.walletHistory.loading ? (
                    "Loading..."
                  ) : props.walletHistory.data.history &&
                    props.walletHistory.data.history.length > 0 ? (
                    <div className="table-form table-responsive wallet-table">
                      <Table className="table">
                        <thead>
                          <tr>
                            <th scope="col text-uppercase">S No</th>
                            <th scope="col text-uppercase">Unique ID</th>
                            <th scope="col text-uppercase">Payment ID</th>
                            <th scope="col text-uppercase">Mode</th>
                            <th scope="col text-uppercase">Total </th>
                            <th scope="col text-uppercase">Paid AT</th>
                            <th scope="col text-uppercase">Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.walletHistory.data.history.map(
                            (history, i) => (
                              <tr>
                                <td>{i + 1}</td>
                                <td>{history.user_wallet_payment_unique_id}</td>
                                <td>{history.payment_id}</td>
                                <td>{history.payment_mode}</td>
                                {history.amount_type == "minus" ? (
                                  <td className="text-color-10">
                                    - {history.requested_amount_formatted}
                                  </td>
                                ) : (
                                  <td className="text-success">
                                    + {history.requested_amount_formatted}
                                  </td>
                                )}
                                <td>{history.paid_date}</td>
                                <td>
                                  <span className="status-icon">
                                    <i className="fa fa-check-circle"></i>
                                    {history.message}
                                  </span>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <NoDataFound />
                  )}
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </div>
      <WithDrawModelSec
        show={show}
        setShow={setShow}
        wallet={props.wallet}
        handleClose={handleClose}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  wallet: state.wallet.walletData,
  walletHistory: state.wallet.walletHistory,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WalletIndex);
