import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "./Account.css";
import { deleteAccountStart } from "../../store/actions/UserAction";

const DeleteAccount = (props) => {
  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(deleteAccountStart({ password: password }));
  };
  return (
    <>
      <div className="main-wrapper">
        <div className="change-password-sec">
          <div className="change-password-card">
            <h4 className="title">HOPE, SEE YOU SOON</h4>
            <p className="desc">
              Note: Once you deleted account, you will lose your history and
              wishlist details
            </p>
            <Form className="change-password-form" onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>PASSWORD</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  value={password}
                  onChange={(event) => setPassword(event.currentTarget.value)}
                />
              </Form.Group>
              <Button
                className="btn-change-password"
                disabled={props.deleteAcc.buttonDisable}
                type="submit"
              >
                {props.deleteAcc.loadingButtonContent !== null
                  ? props.deleteAcc.loadingButtonContent
                  : "Delete Account"}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  deleteAcc: state.users.deleteAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DeleteAccount);
