import {
  FETCH_SINGLE_MODEL_DETAILS_START,
  FETCH_SINGLE_MODEL_DETAILS_SUCCESS,
  FETCH_SINGLE_MODEL_DETAILS_FAILURE,
  FETCH_MODEL_DETAILS_START,
  FETCH_MODEL_DETAILS_SUCCESS,
  FETCH_MODEL_DETAILS_FAILURE,
  FETCH_MODEL_SUB_START,
  FETCH_MODEL_SUB_SUCCESS,
  FETCH_MODEL_SUB_FAILURE,
  FETCH_MODEL_RATING_START,
  FETCH_MODEL_RATING_SUCCESS,
  FETCH_MODEL_RATING_FAILURE,
  FETCH_MODEL_POSTS_START,
  FETCH_MODEL_POSTS_SUCCESS,
  FETCH_MODEL_POSTS_FAILURE,
  FETCH_MODEL_SINGLE_POST_START,
  FETCH_MODEL_SINGLE_POST_SUCCESS,
  FETCH_MODEL_SINGLE_POST_FAILURE,
  FETCH_MODEL_SUGGESTION_START,
  FETCH_MODEL_SUGGESTION_SUCCESS,
  FETCH_MODEL_SUGGESTION_FAILURE,
  MODEL_CATEGORY_UPDATE_START,
  MODEL_CATEGORY_UPDATE_SUCCESS,
  MODEL_CATEGORY_UPDATE_FAILURE,
  FETCH_HOME_START,
  FETCH_HOME_SUCCESS,
  FETCH_HOME_FAILURE,
  MODEL_FILTERS_START,
  MODEL_FILTERS_SUCCESS,
  MODEL_FILTERS_FAILURE,
  FEATURED_MODELS_START,
  FEATURED_MODELS_SUCCESS,
  FEATURED_MODELS_FAILURE,
} from "./ActionConstant";

export function fetchSingleModelStart(data) {
  return {
    type: FETCH_SINGLE_MODEL_DETAILS_START,
    data,
  };
}

export function fetchSingleModelSuccess(data) {
  return {
    type: FETCH_SINGLE_MODEL_DETAILS_SUCCESS,
    data,
  };
}

export function fetchSingleModelFailure(error) {
  return {
    type: FETCH_SINGLE_MODEL_DETAILS_FAILURE,
    error,
  };
}

export function fetchModelDetailsStart(data) {
  return {
    type: FETCH_MODEL_DETAILS_START,
    data,
  };
}

export function fetchModelDetailsSuccess(data) {
  return {
    type: FETCH_MODEL_DETAILS_SUCCESS,
    data,
  };
}

export function fetchModelDetailsFailure(error) {
  return {
    type: FETCH_MODEL_DETAILS_FAILURE,
    error,
  };
}

export function fetchModelSubStart(data) {
  return {
    type: FETCH_MODEL_SUB_START,
    data,
  };
}

export function fetchModelSubSuccess(data) {
  return {
    type: FETCH_MODEL_SUB_SUCCESS,
    data,
  };
}

export function fetchModelSubFailure(error) {
  return {
    type: FETCH_MODEL_SUB_FAILURE,
    error,
  };
}

export function fetchModelRatingStart(data) {
  return {
    type: FETCH_MODEL_RATING_START,
    data,
  };
}

export function fetchModelRatingSuccess(data) {
  return {
    type: FETCH_MODEL_RATING_SUCCESS,
    data,
  };
}

export function fetchModelRatingFailure(error) {
  return {
    type: FETCH_MODEL_RATING_FAILURE,
    error,
  };
}

export function fetchModelPostsStart(data) {
  return {
    type: FETCH_MODEL_POSTS_START,
    data,
  };
}

export function fetchModelPostsSuccess(data) {
  return {
    type: FETCH_MODEL_POSTS_SUCCESS,
    data,
  };
}

export function fetchModelPostsFailure(error) {
  return {
    type: FETCH_MODEL_POSTS_FAILURE,
    error,
  };
}
export function fetchModelSinglePostStart(data) {
  return {
    type: FETCH_MODEL_SINGLE_POST_START,
    data,
  };
}

export function fetchModelSinglePostSuccess(data) {
  return {
    data,
    type: FETCH_MODEL_SINGLE_POST_SUCCESS,
  };
}

export function fetchModelSinglePostFailure(error) {
  return {
    type: FETCH_MODEL_SINGLE_POST_FAILURE,
    error,
  };
}
export function fetchModelSuggestionStart(data) {
  return {
    type: FETCH_MODEL_SUGGESTION_START,
    data,
  };
}

export function fetchModelSuggestionSuccess(data) {
  return {
    type: FETCH_MODEL_SUGGESTION_SUCCESS,
    data,
  };
}

export function fetchModelSuggestionFailure(error) {
  return {
    type: FETCH_MODEL_SUGGESTION_FAILURE,
    error,
  };
}

export function modelCategoryUpdateStart(data) {
  return {
    type: MODEL_CATEGORY_UPDATE_START,
    data,
  };
}

export function modelCategoryUpdateSuccess(data) {
  return {
    type: MODEL_CATEGORY_UPDATE_SUCCESS,
    data,
  };
}

export function modelCategoryUpdateFailure(error) {
  return {
    type: MODEL_CATEGORY_UPDATE_FAILURE,
    error,
  };
}

export function fetchHomeStart(data) {
  return {
    type: FETCH_HOME_START,
    data,
  };
}

export function fetchHomeSuccess(data) {
  return {
    type: FETCH_HOME_SUCCESS,
    data,
  };
}

export function fetchHomeFailure(error) {
  return {
    type: FETCH_HOME_FAILURE,
    error,
  };
}

export function modelFiltersStart(data) {
  return {
    type: MODEL_FILTERS_START,
    data,
  };
}

export function modelFiltersSuccess(data) {
  return {
    type: MODEL_FILTERS_SUCCESS,
    data,
  };
}

export function modelFiltersFailure(error) {
  return {
    type: MODEL_FILTERS_FAILURE,
    error,
  };
}

export function featuredModelsStart(data) {
  return {
    type: FEATURED_MODELS_START,
    data,
  };
}

export function featuredModelsSuccess(data) {
  return {
    type: FEATURED_MODELS_SUCCESS,
    data,
  };
}

export function featuredModelsFailure(error) {
  return {
    type: FEATURED_MODELS_FAILURE,
    error,
  };
}
