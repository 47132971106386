import {
  FETCH_CATEGORY_START,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_FAILURE,
  FETCH_SUB_CATEGORY_START,
  FETCH_SUB_CATEGORY_SUCCESS,
  FETCH_SUB_CATEGORY_FAILURE,
  FETCH_OCCASION_START,
  FETCH_OCCASION_SUCCESS,
  FETCH_OCCASION_FAILURE,
} from "./ActionConstant";

export function fetchCategoryStart(data) {
  return {
    type: FETCH_CATEGORY_START,
    data,
  };
}

export function fetchCategorySuccess(data) {
  return {
    type: FETCH_CATEGORY_SUCCESS,
    data,
  };
}

export function fetchCategoryFailure(error) {
  return {
    type: FETCH_CATEGORY_FAILURE,
    error,
  };
}

export function fetchSubCategoryStart(data) {
  return {
    type: FETCH_SUB_CATEGORY_START,
    data,
  };
}

export function fetchSubCategorySuccess(data) {
  return {
    type: FETCH_SUB_CATEGORY_SUCCESS,
    data,
  };
}

export function fetchSubCategoryFailure(error) {
  return {
    type: FETCH_SUB_CATEGORY_FAILURE,
    error,
  };
}

export function fetchOccasionStart(data) {
  return {
    type: FETCH_OCCASION_START,
    data,
  };
}

export function fetchOccasionSuccess(data) {
  return {
    type: FETCH_OCCASION_SUCCESS,
    data,
  };
}

export function fetchOccasionFailure(error) {
  return {
    type: FETCH_OCCASION_FAILURE,
    error,
  };
}
