import {
  FETCH_SUBSCRIPTION_START,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_FAILURE,
  FETCH_MY_SUBSCRIPTION_START,
  FETCH_MY_SUBSCRIPTION_SUCCESS,
  FETCH_MY_SUBSCRIPTION_FAILURE,
  FETCH_SINGLE_SUBSCRIPTION_START,
  FETCH_SINGLE_SUBSCRIPTION_SUCCESS,
  FETCH_SINGLE_SUBSCRIPTION_FAILURE,
  SUBSCRIPTION_PAYMENT_STRIPE_START,
  SUBSCRIPTION_PAYMENT_STRIPE_SUCCESS,
  SUBSCRIPTION_PAYMENT_STRIPE_FAILURE,
  SUBSCRIPTION_PAYMENT_WALLET_START,
  SUBSCRIPTION_PAYMENT_WALLET_SUCCESS,
  SUBSCRIPTION_PAYMENT_WALLET_FAILURE,
  SUBSCRIPTION_AUTO_RENEWAL_START,
  SUBSCRIPTION_AUTO_RENEWAL_SUCCESS,
  SUBSCRIPTION_AUTO_RENEWAL_FAILURE,
  SUBSCRIPTION_COUPON_CHECK_START,
  SUBSCRIPTION_COUPON_CHECK_SUCCESS,
  SUBSCRIPTION_COUPON_CHECK_FAILURE,
  SUBSCRIPTION_CREATE_START,
  SUBSCRIPTION_CREATE_SUCCESS,
  SUBSCRIPTION_CREATE_FAILURE,
  SUBSCRIPTION_STATUS_UPDATE_START,
  SUBSCRIPTION_STATUS_UPDATE_SUCCESS,
  SUBSCRIPTION_STATUS_UPDATE_FAILURE,
  SUBSCRIPTION_MAKE_DEFAULT_START,
  SUBSCRIPTION_MAKE_DEFAULT_SUCCESS,
  SUBSCRIPTION_MAKE_DEFAULT_FAILURE,
  SUBSCRIPTION_VIEW_START,
  SUBSCRIPTION_VIEW_SUCCESS,
  SUBSCRIPTION_VIEW_FAILURE,
  SUBSCRIPTION_DELETE_START,
  SUBSCRIPTION_DELETE_SUCCESS,
  SUBSCRIPTION_DELETE_FAILURE,
} from "./ActionConstant";

// Get subscription actions.

export function fetchSubscriptionStart(data) {
  return {
    type: FETCH_SUBSCRIPTION_START,
    data,
  };
}

export function fetchSubscriptionSuccess(data) {
  return {
    type: FETCH_SUBSCRIPTION_SUCCESS,
    data,
  };
}

export function fetchSubscriptionFailure(error) {
  return {
    type: FETCH_SUBSCRIPTION_FAILURE,
    error,
  };
}

// Get My subscription actions.

export function fetchMySubscriptionStart(data) {
  return {
    type: FETCH_MY_SUBSCRIPTION_START,
    data,
  };
}

export function fetchMySubscriptionSuccess(data) {
  return {
    type: FETCH_MY_SUBSCRIPTION_SUCCESS,
    data,
  };
}

export function fetchMySubscriptionFailure(error) {
  return {
    type: FETCH_MY_SUBSCRIPTION_FAILURE,
    error,
  };
}

// Get single subscription actions.

export function fetchSingleSubscriptionStart(data) {
  return {
    type: FETCH_SINGLE_SUBSCRIPTION_START,
    data,
  };
}

export function fetchSingleSubscriptionSuccess(data) {
  return {
    type: FETCH_SINGLE_SUBSCRIPTION_SUCCESS,
    data,
  };
}

export function fetchSingleSubscriptionFailure(error) {
  return {
    type: FETCH_SINGLE_SUBSCRIPTION_FAILURE,
    error,
  };
}

// Subscription Payment stripe actions.

export function subscriptionPaymentStripeStart(data) {
  return {
    type: SUBSCRIPTION_PAYMENT_STRIPE_START,
    data,
  };
}

export function subscriptionPaymentStripeSuccess(data) {
  return {
    type: SUBSCRIPTION_PAYMENT_STRIPE_SUCCESS,
    data,
  };
}

export function subscriptionPaymentStripeFailure(error) {
  return {
    type: SUBSCRIPTION_PAYMENT_STRIPE_FAILURE,
    error,
  };
}

// Subscription Payment wallet actions.

export function subscriptionPaymentWalletStart(data) {
  return {
    type: SUBSCRIPTION_PAYMENT_WALLET_START,
    data,
  };
}

export function subscriptionPaymentWalletSuccess(data) {
  return {
    type: SUBSCRIPTION_PAYMENT_WALLET_SUCCESS,
    data,
  };
}

export function subscriptionPaymentWalletFailure(error) {
  return {
    type: SUBSCRIPTION_PAYMENT_WALLET_FAILURE,
    error,
  };
}

// Subscription auto renewal actions.

export function subscriptionAutoRenewalStart(data) {
  return {
    type: SUBSCRIPTION_AUTO_RENEWAL_START,
    data,
  };
}

export function subscriptionAutoRenewalSuccess(data) {
  return {
    type: SUBSCRIPTION_AUTO_RENEWAL_SUCCESS,
    data,
  };
}

export function subscriptionAutoRenewalFailure(error) {
  return {
    type: SUBSCRIPTION_AUTO_RENEWAL_FAILURE,
    error,
  };
}

export function subscriptionCouponCheckStart(data) {
  return {
    type: SUBSCRIPTION_COUPON_CHECK_START,
    data,
  };
}

export function subscriptionCouponCheckSuccess(data) {
  return {
    type: SUBSCRIPTION_COUPON_CHECK_SUCCESS,
    data,
  };
}

export function subscriptionCouponCheckFailure(error) {
  return {
    type: SUBSCRIPTION_COUPON_CHECK_FAILURE,
    error,
  };
}

export function subscriptionCreateStart(data) {
  return {
    type: SUBSCRIPTION_CREATE_START,
    data,
  };
}

export function subscriptionCreateSuccess(data) {
  return {
    type: SUBSCRIPTION_CREATE_SUCCESS,
    data,
  };
}

export function subscriptionCreateFailure(error) {
  return {
    type: SUBSCRIPTION_CREATE_FAILURE,
    error,
  };
}

export function subscriptionStatusUpdateStart(data) {
  return {
    type: SUBSCRIPTION_STATUS_UPDATE_START,
    data,
  };
}

export function subscriptionStatusUpdateSuccess(data) {
  return {
    type: SUBSCRIPTION_STATUS_UPDATE_SUCCESS,
    data,
  };
}

export function subscriptionStatusUpdateFailure(error) {
  return {
    type: SUBSCRIPTION_STATUS_UPDATE_FAILURE,
    error,
  };
}

export function subscriptionViewStart(data) {
  return {
    type: SUBSCRIPTION_VIEW_START,
    data,
  };
}

export function subscriptionViewSuccess(data) {
  return {
    type: SUBSCRIPTION_VIEW_SUCCESS,
    data,
  };
}

export function subscriptionViewFailure(error) {
  return {
    type: SUBSCRIPTION_VIEW_FAILURE,
    error,
  };
}

export function subscriptionDeleteStart(data) {
  return {
    type: SUBSCRIPTION_DELETE_START,
    data,
  };
}

export function subscriptionDeleteSuccess(data) {
  return {
    type: SUBSCRIPTION_DELETE_SUCCESS,
    data,
  };
}

export function subscriptionDeleteFailure(error) {
  return {
    type: SUBSCRIPTION_DELETE_FAILURE,
    error,
  };
}

export function subscriptionMakeDefaultStart(data) {
  return {
    type: SUBSCRIPTION_MAKE_DEFAULT_START,
    data,
  };
}

export function subscriptionMakeDefaultSuccess(data) {
  return {
    type: SUBSCRIPTION_MAKE_DEFAULT_SUCCESS,
    data,
  };
}

export function subscriptionMakeDefaultFailure(error) {
  return {
    type: SUBSCRIPTION_MAKE_DEFAULT_FAILURE,
    error,
  };
}
