import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageLoader from "../Helper/ImageLoader";
import HomeFeatureLoader from "../Handlers/Loaders/HomeFeatureLoader";
import NoDataFound from "../NoDataFound/NoDataFound";

const ModelSuggestionSec = (props) => {
  return (
    <>
      <div className="home-featured-sec single-filter-people-view-sec">
        <Container fluid>
          <Row>
            <Col md={12}>
              <div className="home-featured-header-sec">
                <Link to="#">
                  <h1 className="slider-header-title">People also viewed</h1>
                </Link>
              </div>
              <div className="slider-featured-sec">
                {props.models.loading ? (
                  <HomeFeatureLoader />
                ) : props.models.data.models.length > 0 ? (
                  props.models.data.models.map((model) => (
                    <div>
                      <Link to={`/${model.username}`}>
                        <div className="feature-item">
                          <div className="featured-card">
                            <div className="overlay-slider"></div>
                            <ImageLoader
                              image={model.picture}
                              alt="user-image"
                              className="featured-img"
                            />
                            {model.is_featured ? (
                              <span className="flag-icon-img-sec">
                                <Image
                                  src={model.picture}
                                  alt="user-image"
                                  className="flag-icon-img"
                                />
                              </span>
                            ) : null}
                            <p className="featured-desc">
                              {model.subscrition_amount}
                            </p>
                          </div>
                          <div className="featured-details-sec">
                            <div className="featured-details">
                              <Link to="#" className="title">
                                {model.name}
                              </Link>
                            </div>
                            <div className="featured-details">
                              <Link to="#" className="desc">
                                {model.catgeory_name}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                ) : (
                  <NoDataFound />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ModelSuggestionSec);
