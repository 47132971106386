import React, { useState, useEffect, useLayoutEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./Auth.css";
import { userLoginStart,userRegisterStart } from "../../store/actions/UserAction";
import SocialButton from "../Helper/SocialButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;

const $ = window.$;

const LoginIndex = (props) => {
  const [loginInputData, setLoginInputData] = useState({});

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleLogin = (event) => {
    event.preventDefault();
    props.dispatch(userLoginStart(loginInputData));
  };

  const handleFacebookLogin = (user) => {
    console.log("handleFacebookLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        email: user._profile.email ? user._profile.email : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "facebook",
      })
    );
  };

  const handleGoogleLogin = (user) => {
      console.log("handleGoogleLogin", user._profile);
      props.dispatch(
        userRegisterStart({
          name: user._profile.name,
          email: user._profile.email,
          first_name: user._profile.firstName ? user._profile.firstName : "",
          last_name: user._profile.lastName ? user._profile.lastName : "",
          social_unique_id: user._profile.id,
          picture: user._profile.profilePicURL,
          login_by: "google",
        })
      );
  };

  const handleSocialLoginFailure = (err) => {
      console.error(err);
  };

  useEffect(() => {}, []);

  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <>
      <div className="main-wrapper">
        <Container>
          <div className="auth-warpper">
            <Row>
              <Col
                md={{ span: 6, offset: 3 }}
                sm={6}
                lg={{ span: 6, offset: 3 }}
                xl={{ span: 4, offset: 4 }}
              >
                <div className="login-box">
                  <h2>Log in to {configuration.get("configData.site_name")}</h2>
                  <div className="login-type-sec">

                    {configuration.get("configData.FB_CLIENT_ID") ? (
                      <SocialButton
                        provider="facebook"
                        appId={configuration.get("configData.FB_CLIENT_ID")}
                        onLoginSuccess={handleFacebookLogin}
                        onLoginFailure={handleSocialLoginFailure}
                        className="btn-facebook"
                        id="facebook-connect"
                      >
                        <span>Sign In / Login with Facebook</span>
                      </SocialButton>
                    ) : (
                      ""
                    )}
                    {configuration.get("configData.GOOGLE_CLIENT_ID") ? (
                      <SocialButton
                        provider="google"
                        key={"google"}
                        appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
                        onLoginSuccess={handleGoogleLogin}
                        onLoginFailure={handleSocialLoginFailure}
                        className="btn-apple"
                        id="google-connect"
                      >
                        <span>Sign Up / Login with Google</span>
                      </SocialButton>
                    ) : (
                      ""
                    )}                    
                  </div>

                  {configuration.get("configData.GOOGLE_CLIENT_ID") ||
                  configuration.get("configData.FB_CLIENT_ID") ? (
                    <div className="or-sec">
                      <span className="or-text">OR</span>
                    </div>
                  ) : (
                    <span classsName="login-or-hide"></span>
                  )}

                  <Form className="login-form" onSubmit={handleLogin}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="you@example.com"
                        value={loginInputData.email}
                        name="email"
                        onChange={(event) =>
                          setLoginInputData({
                            ...loginInputData,
                            email: event.currentTarget.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type={passwordShown ? "text" : "password"}
                        placeholder="Password"
                        value={loginInputData.password}
                        name="password"
                        onChange={(event) =>
                          setLoginInputData({
                            ...loginInputData,
                            password: event.currentTarget.value,
                          })
                        }
                      />
                    <i className="toggle-password" onClick={togglePasswordVisiblity}>{eye}</i>{" "}

                    </Form.Group>
                    <Button
                      className="btn-email"
                      onClick={handleLogin}
                      disabled={props.login.buttonDisable}
                    >
                      {props.login.loadingButtonContent !== null
                        ? props.login.loadingButtonContent
                        : "Login"}
                    </Button>
                  </Form>
                  <div className="login-footer-main-sec">
                    <span className="login-desc-text">
                      By signing up, you agree to{" "}
                      {configuration.get("configData.site_name")}'s
                      <Link to={"/page/terms"}> Terms of Service</Link>,
                      including
                      <Link to={"/page/terms"}> Additional Terms</Link>, and
                      <Link to={"/page/privacy"}> Privacy Policy</Link>
                    </span>
                  </div>
                  <div className="login-footer-sec">
                    <span className="login-desc-text">
                      <Link to={"/forgot-password"}>Forgot your password?</Link>
                    </span>
                  </div>
                  <div className="login-footer-sub-sec">
                    <span className="login-desc-text">
                      Don't have an account?
                      <Link to={"/signup"}>Sign up</Link>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  login: state.users.loginInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LoginIndex);
