import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModelListSecLoader from "../Handlers/Loaders/ModelListSecLoader";
import NoDataFound from "../NoDataFound/NoDataFound";

const ModelListSec = (props) => {
  return (
    <>
      <div className="home-featured-sec">
        <div className="home-featured-header-sec">
          <Link to="#">
            <h1 className="slider-header-title">All in {props.heading}</h1>
          </Link>
        </div>
        <div className="slider-all-sec">
          <Row>
            {props.model.loading ? (
              <ModelListSecLoader />
            ) : props.model.data.models.length > 0 ? (
              props.model.data.models.map((model) => (
                <Col md={3}>
                  <div>
                    <Link to={`/${model.username}`}>
                      <div className="feature-item">
                        <div className="featured-card">
                          <div className="overlay-slider"></div>
                          <Image
                            src={model.picture}
                            alt="user-image"
                            className="featured-img"
                          />
                          {model.is_featured ? (
                            <span className="flag-icon-img-sec">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/images/featured.svg"
                                }
                                alt="user-image"
                                className="flag-icon-img"
                              />
                            </span>
                          ) : null}
                          <p className="featured-desc">
                            {model.subscription_amount}
                          </p>
                        </div>
                        <div className="featured-details-sec">
                          <div className="featured-details">
                            <Link to={`/${model.username}`} className="title">
                              {model.name}
                            </Link>
                          </div>
                          <div className="featured-details">
                            <Link to="#" className="desc">
                              {model.category_name}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Col>
              ))
            ) : (
              <NoDataFound />
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(ModelListSec);
