import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./TextMe.css";
import { Container, Row, Col, Form, InputGroup, FormControl, Image } from "react-bootstrap";

const TextMe = (props) => {
    useEffect(() => { }, []);
    return (
        <>
            <div className="main-wrapper text-me-wrapper">
                <div className="text-me-sec-wrapper">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className="text-me-card">
                                    <Row>
                                        <Col md={7} xl={6} className="resp-mrg-btm-xs">
                                        <h1 className="title">
                                            Want to get the full Cameo Experience?
                                            <br></br>
                                            <span>Download our app.</span>
                                        </h1>
                                        <p className="desc">
                                            Chat with celebrities. Get even more fun content. Better sharing capabilities and just all around more of the good stuff.
                                        </p>
                                        <Form>
                                            <InputGroup className="">
                                                <FormControl
                                                placeholder="(XXX) XXX-XXXX"
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                                />
                                                <InputGroup.Append>
                                                <InputGroup.Text id="basic-addon2">Send</InputGroup.Text>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form>
                                        </Col>
                                        <Col md={5} xl={6}>
                                            <div  className="qr-code-sec">
                                                <h6>Or use our cool QR code!</h6>
                                                <p className="desc">
                                                Open your camera app on your phone and hold it up to the screen. A link should popup that will bring you right to the app store.
                                                </p>
                                                <canvas height="128" width="128"></canvas>
                                            </div>
                                        </Col>
                                    </Row> 
                                </div>
                                <Row>
                                    <Col md={6} xl={4} className="resp-mrg-btm-md">
                                        <div className="text-me-card-sec">
                                            <div className="text-me-item">
                                                <div className="text-me-img-sec">
                                                    <Image
                                                        src={
                                                        window.location.origin +
                                                        "/assets/images/message.svg"
                                                        }
                                                        alt="user-image" 
                                                        className="text-me-icon"
                                                    />
                                                </div>
                                                <div className="text-me-info">
                                                    <h5>Chat with Celebrities</h5>
                                                    <p>Cameo Direct is our exclusive app feature where you chat with your favorite celebrities! Let them know you're a big fan, send them fan stickers or just say </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6} xl={4} className="resp-mrg-btm-md">
                                        <div className="text-me-card-sec">
                                            <div className="text-me-item">
                                                <div className="text-me-img-sec">
                                                    <Image
                                                        src={
                                                        window.location.origin +
                                                        "/assets/images/phone.svg"
                                                        }
                                                        alt="user-image" 
                                                        className="text-me-icon"
                                                    />
                                                </div>
                                                <div className="text-me-info">
                                                    <h5>Access to more content</h5>
                                                    <p>Get even more great content, only on our app. Watch videos from your favorite celebrities, check out hilarious fan reactions and more. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6} xl={4}>
                                        <div className="text-me-card-sec">
                                            <div className="text-me-item">
                                                <div className="text-me-img-sec">
                                                    <Image
                                                        src={
                                                        window.location.origin +
                                                        "/assets/images/gift-1.svg"
                                                        }
                                                        alt="user-image" 
                                                        className="text-me-icon"
                                                    />
                                                </div>
                                                <div className="text-me-info">
                                                    <h5>Share like never befores</h5>
                                                    <p>With the app, sharing is a whole lot easier. You can share an invite code and get $5 or share your Cameos on social. Sweet!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row> 
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    page: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TextMe);
