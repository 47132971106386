import React, { useState, useEffect, Component } from "react";
import { connect } from "react-redux";
import "./RequestBookingIndex.css";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Badge,
  InputGroup,
  FormControl,
  Image,
} from "react-bootstrap";
import ImageLoader from "../Helper/ImageLoader";
import NoDataFound from "../NoDataFound/NoDataFound";

const RequestBookingCard = (props) => {
  useEffect(() => {
    props.setInputData({
      ...props.inputData,
      from_username: localStorage.getItem("username"),
    });
  }, []);

  const [isVisible, setIsVisible] = useState(true);

  return (
    <>
      <div className="request-booking-card">
        <div className="request-booking-profile-sec">
          <ImageLoader
            image={props.singleModel.data.user.picture}
            alt="user-image"
            className="user-img"
          />
          <p className="profile-name">
            New request for {props.singleModel.data.user.name}
          </p>
        </div>
        <div className="request-book-tab-sec">
          <p className="sub-title">Who is this for?</p>
          <Form>
            <div className="radio-nav-tab-list-sec">
              <div className="request-felx">
                <input
                  type="radio"
                  id="request-tab-1"
                  value={1}
                  name="type"
                  onChange={(event) =>
                    props.setInputData({
                      ...props.inputData,
                      type: event.currentTarget.value,
                    })
                  }
                />
                <label
                  htmlFor="request-tab-1"
                  onClick={() => setIsVisible(true)}
                >
                  <div className="request-nav-icon-sec">
                    <Image
                      src={window.location.origin + "/assets/images/gift.svg"}
                      alt="user-image"
                      className="request-nav-icon"
                    />
                  </div>
                  <p className="nav-desc">Someone else</p>
                </label>
              </div>

              <div className="request-felx">
                <input
                  type="radio"
                  id="request-tab-2"
                  value={2}
                  name="type"
                  onChange={(event) =>
                    props.setInputData({
                      ...props.inputData,
                      type: event.currentTarget.value,
                    })
                  }
                />
                <label
                  htmlFor="request-tab-2"
                  onClick={() => setIsVisible(false)}
                >
                  <div className="request-nav-icon-sec">
                    <i className="far fa-user user-icon"></i>
                  </div>
                  <p className="nav-desc">Myself</p>
                </label>
              </div>

              <div className="request-felx">
                <input
                  type="radio"
                  id="request-tab-3"
                  value={3}
                  name="type"
                  onChange={(event) =>
                    props.setInputData({
                      ...props.inputData,
                      type: event.currentTarget.value,
                    })
                  }
                />
                <label
                  htmlFor="request-tab-3"
                  onClick={() => setIsVisible(true)}
                >
                  <div className="request-nav-icon-sec">
                    <Image
                      src={
                        window.location.origin + "/assets/images/briefcase.svg"
                      }
                      alt="user-image"
                      className="request-nav-icon"
                    />
                  </div>
                  <p className="nav-desc">Brand/business</p>
                  {/* <Badge variant="secondary" className="amount-badge">
                    $500
                  </Badge> */}
                </label>
              </div>
            </div>

            <div className="request-nav-sec">
              <div className="request-book-tab-content-sec">
                <Form>
                  <div className="request-booking-form">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>To</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Shia"
                        name="to_username"
                        value={props.inputData.to_username}
                        onChange={(event) =>
                          props.setInputData({
                            ...props.inputData,
                            to_username: event.currentTarget.value,
                          })
                        }
                      />
                      <Form.Text className="short-desc">Required</Form.Text>
                    </Form.Group>
                    {isVisible && (
                      <div>
                        <Form.Label>From</Form.Label>
                        <InputGroup className="input-right-border mb-3">
                          <FormControl
                            placeholder={localStorage.getItem("username")}
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            name="from_username"
                            value={props.inputData.from_username}
                            onChange={(event) =>
                              props.setInputData({
                                ...props.inputData,
                                from_username: event.currentTarget.value,
                              })
                            }
                          />
                          <InputGroup.Append>
                            <InputGroup.Text id="basic-addon2">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/images/cancel-white.svg"
                                }
                                alt="user-image"
                                className="cancel-icon"
                              />
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                    )}
                    <Form.Group controlId="pronoun-select">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/select-arrow-white.svg"
                        }
                        alt="user-image"
                        className="select-arrow"
                      />
                      <Form.Label>Pronoun</Form.Label>
                      <Form.Control
                        as="select"
                        name="pronoun"
                        onChange={(event) =>
                          props.setInputData({
                            ...props.inputData,
                            pronoun: event.currentTarget.value,
                          })
                        }
                      >
                        <option value="">Select</option>
                        <option value="They/Them: Wish them a happy birthday!">
                          They/Them: "Wish them a happy birthday!"
                        </option>
                        <option value="She/Her: Wish her a happy birthday!">
                          She/Her: "Wish her a happy birthday!"
                        </option>
                        <option value="He/Him: Wish him a happy birthday!">
                          He/Him: "Wish him a happy birthday!"
                        </option>
                        <option value="Pronoun not listed - will clarify in request">
                          Pronoun not listed - will clarify in request
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </div>
                  <div className="choose-occasion-form">
                    <h4 className="title">Select an occasion</h4>
                    <div className="radio-group-sec">
                      <div type="rounded" className="radio-btn-sec" style={{display:'none'}}>
                        <input
                          type="radio"
                          id="none"
                          value="none"
                          name="occasion_id"
                          onChange={(event) =>
                            props.setInputData({
                              ...props.inputData,
                              occasion_id: "",
                            })
                          }
                        />
                        <label htmlFor="none">
                          <div className="radio-icon-sec">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/none-white-icon.svg"
                              }
                              alt="user-image"
                              className="radio-icon"
                            />
                          </div>
                          <span>None</span>
                        </label>
                      </div>

                      {props.occasion.loading ? (
                        "Loading..."
                      ) : props.occasion.data.occasions.length > 0 ? (
                        props.occasion.data.occasions.map((occasion) => (
                          <div type="rounded" className="radio-btn-sec">
                            <input
                              type="radio"
                              id={occasion.occasion_id}
                              value={occasion.occasion_id}
                              name="occasion_id"
                              onChange={(event) =>
                                props.setInputData({
                                  ...props.inputData,
                                  occasion_id: event.currentTarget.value,
                                })
                              }
                            />
                            <label htmlFor={occasion.occasion_id}>
                              <div className="radio-icon-sec">
                                <ImageLoader
                                  image={occasion.picture}
                                  alt="user-image"
                                  className="radio-icon"
                                />
                              </div>
                              <span>{occasion.name}</span>
                            </label>
                          </div>
                        ))
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div className="instruction-card">
        <h3 className="title">Instructions</h3>
        <Form className="instruction-form">
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              My instructions for {props.singleModel.data.user.name} are
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              className="height-auto-form"
              placeholder="Hey I'm having a tough week with classes and I could use some cheering up!"
              name="instructions"
              onChange={(event) =>
                props.setInputData({
                  ...props.inputData,
                  instructions: event.currentTarget.value,
                })
              }
              maxLength="250"
            />
            <Form.Text className="short-desc">
              Required <span>250</span>
            </Form.Text>
            <Form.Text className="short-note-1">
              Don't forget to add pronunciation, it really helps.
            </Form.Text>
          </Form.Group>

          <Form.Check
            type="checkbox"
            id="post_display_type"
            label={`Hide this video from ${props.singleModel.data.user.name} profile`}
            value={1}
            onChange={(event) => {
              if (event.currentTarget.checked)
                props.setInputData({
                  ...props.inputData,
                  post_display_type: 1,
                });
              else
                props.setInputData({
                  ...props.inputData,
                  post_display_type: 0,
                });
            }}
            custom
          />
          <div className="continue-btn-sec">
            <Button
              className="btn-continue"
              type="submit"
              disabled={props.saveBooking.buttonDisabled}
              onClick={props.handleSubmit}
            >
              {props.saveBooking.loadingButtonContent !== null
                ? props.saveBooking.loadingButtonContent
                : "Continue"}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapDispatchToProps)(RequestBookingCard);
