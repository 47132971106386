import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageLoader from "../Helper/ImageLoader";
import HomeFeatureLoader from "../Handlers/Loaders/HomeFeatureLoader";
import NoDataFound from "../NoDataFound/NoDataFound";

const HomeFeatured = (props) => {
  return (
    <>
      {props.featuredModel.loading ? (
        <HomeFeatureLoader />
      ) : props.featuredModel.data.models.length > 0 ? (
        <div className="home-featured-sec">
          <Container fluid>
            <Row>
              <Col md={12}>
                <div className="home-featured-header-sec">
                  <Link to="/browse/featured">
                    <h1 className="slider-header-title">Featured</h1>
                  </Link>
                  <Link to="/browse/featured">
                    <p className="slider-header-link">See All</p>
                  </Link>
                </div>
                <div className="slider-featured-sec">
                  {props.featuredModel.data.models.map((model) => (
                    <div>
                      <Link to={`/${model.username}`}>
                        <div className="feature-item">
                          <div className="featured-card">
                            <div className="overlay-slider"></div>
                            <ImageLoader
                              image={model.picture}
                              alt="user-image"
                              className="featured-img"
                            />
                            <span className="flag-icon-img-sec">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/images/featured.svg"
                                }
                                alt="user-image"
                                className="flag-icon-img"
                              />
                            </span>
                            <p className="featured-desc">
                              {model.subscription_amount}
                            </p>
                          </div>
                          <div className="featured-details-sec">
                            <div className="featured-details">
                              <Link to={`/${model.username}`} className="title">
                                {model.name}
                              </Link>
                            </div>
                            <div className="featured-details">
                              <Link to={`/${model.username}`} className="desc">
                                {model.category_name}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapDispatchToProps)(HomeFeatured);
