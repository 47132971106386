import React, { useState, useEffect, useLayoutEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./Auth.css";
import {
  usernameAvailablityStart,
  userRegisterStart,
} from "../../store/actions/UserAction";
import SocialButton from "../Helper/SocialButton";

const $ = window.$;

const SignupIndex = (props) => {
  const [signupInputData, setSignupInputData] = useState({
    username: "",
  });

  const handleSignup = (event) => {
    event.preventDefault();

    props.dispatch(userRegisterStart(signupInputData));
  };

  const usernameCheck = (event) => {
    setSignupInputData({
      ...signupInputData,
      username: event.currentTarget.value,
    });
    if (signupInputData.username.length > 4)
      props.dispatch(
        usernameAvailablityStart({
          username: event.currentTarget.value,
        })
      );
  };

  const handleFacebookLogin = (user) => {
    console.log("handleFacebookLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        email: user._profile.email ? user._profile.email : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "facebook",
      })
    );
  };

  const handleGoogleLogin = (user) => {
    console.log("handleGoogleLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        email: user._profile.email,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "google",
      })
    );
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  useEffect(() => {}, []);

  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className="main-wrapper">
        <Container>
          <div className="auth-warpper">
            <Row>
              <Col
                md={{ span: 6, offset: 3 }}
                sm={6}
                lg={{ span: 6, offset: 3 }}
                xl={{ span: 4, offset: 4 }}
              >
                <div className="login-box">
                  <h2>
                    Welcome to {configuration.get("configData.site_name")}
                  </h2>
                  <p>
                    Book personalized video shoutouts from your favorite people
                  </p>
                  <div className="login-type-sec">
                    {configuration.get("configData.FB_CLIENT_ID") ? (
                      <SocialButton
                        provider="facebook"
                        appId={configuration.get("configData.FB_CLIENT_ID")}
                        onLoginSuccess={handleFacebookLogin}
                        onLoginFailure={handleSocialLoginFailure}
                        className="btn-facebook"
                        id="facebook-connect"
                      >
                        <span>Sign In / Login with Facebook</span>
                      </SocialButton>
                    ) : (
                      ""
                    )}
                    {configuration.get("configData.GOOGLE_CLIENT_ID") ? (
                      <SocialButton
                        provider="google"
                        key={"google"}
                        appId={configuration.get("configData.GOOGLE_CLIENT_ID")}
                        onLoginSuccess={handleGoogleLogin}
                        onLoginFailure={handleSocialLoginFailure}
                        className="btn-apple"
                        id="google-connect"
                      >
                        <span>Sign Up / Login with Google</span>
                      </SocialButton>
                    ) : (
                      ""
                    )}
                  </div>

                  {configuration.get("configData.GOOGLE_CLIENT_ID") ||
                  configuration.get("configData.FB_CLIENT_ID") ? (
                    <div className="or-sec">
                      <span className="or-text">OR</span>
                    </div>
                  ) : (
                    <span classsName="login-or-hide"></span>
                  )}
                  <Form className="login-form" onSubmit={handleSignup}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Pepe Sylvia"
                        value={signupInputData.name}
                        name="name"
                        onChange={(event) =>
                          setSignupInputData({
                            ...signupInputData,
                            name: event.currentTarget.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Pepe Sylvia"
                        value={signupInputData.username}
                        name="username"
                        onChange={usernameCheck}
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="pepe@example.com"
                        value={signupInputData.email}
                        name="email"
                        onChange={(event) =>
                          setSignupInputData({
                            ...signupInputData,
                            email: event.currentTarget.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value={signupInputData.password}
                        name="password"
                        onChange={(event) =>
                          setSignupInputData({
                            ...signupInputData,
                            password: event.currentTarget.value,
                          })
                        }
                      />
                    </Form.Group>
                    {/* <Form.Group controlId="formBasicEmail">
                      <Form.Label>Invite code (Optional)</Form.Label>
                      <Form.Control type="email" placeholder="Pepe Sylvia" />
                    </Form.Group> */}
                    <Button
                      className="btn-signup"
                      onClick={handleSignup}
                      disabled={props.signup.buttonDisable}
                    >
                      {props.signup.loadingButtonContent !== null
                        ? props.signup.loadingButtonContent
                        : "SIGN UP"}
                    </Button>
                  </Form>
                  <div className="login-footer-sub-sec-1">
                    <span className="login-desc-text-1">
                      <Link to="/login">Already have an account? Sign In</Link>
                    </span>
                  </div>
                  <Form className="signup-check-form hidden">
                    <Form.Check
                      type="checkbox"
                      id="customControlAutosizing"
                      label="Email me exclusive offers and discounts"
                      custom
                    />
                  </Form>
                  <div className="login-footer-main-sec-1">
                    <span className="login-desc-text">
                      By creating an account you agree to{" "}
                      {configuration.get("configData.site_name")} 's
                      <Link to="/page/terms">Terms of Service</Link> and
                      <Link to="/page/privacy">Privacy Policy</Link>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  signup: state.users.registerInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SignupIndex);
