import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

class HomeBlog extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="home-blog-sec">
          <Container fluid>
            <Row>
              <Col md={4} className="resp-mrg-btm-xs">
                <div className="blog-item">
                  <div className="blog-img-sec">
                    <Link to="/browse/featured">
                      <Image
                        src={
                          window.location.origin + "/assets/images/blog-img.jpg"
                        }
                        alt="user-image"
                        className="blog-img"
                      />
                    </Link>
                  </div>
                  <div className="blog-details-sec">
                    <Link to="/browse/featured">
                      <h4 className="title">Valuable finds under $100</h4>
                    </Link>
                    <p className="desc">
                      We rounded up celebs offering top-notch Cameos at an
                      incredible value. Go ahead, take a peek.
                    </p>
                  </div>
                  <div className="blog-view-all-sec">
                    <Link to="/browse/featured" className="see-more-link">
                      See All{" "}
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        color="white"
                        className="ml-1"
                      />
                    </Link>
                  </div>
                </div>
              </Col>
              <Col md={4} className="resp-mrg-btm-xs">
                <div className="blog-item">
                  <div className="blog-img-sec">
                    <Link to="/browse/trending">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/blog-img-1.jpg"
                        }
                        alt="user-image"
                        className="blog-img"
                      />
                    </Link>
                  </div>
                  <div className="blog-details-sec">
                    <Link to="/browse/trending">
                      <h4 className="title">They say all the right things.</h4>
                    </Link>
                    <p className="desc">
                      They’re at the top of their game and now they’re at the
                      top of our list! Check out these Cameo Legends for
                      yourself and discover why people can’t get enough of them.
                    </p>
                  </div>
                  <div className="blog-view-all-sec">
                    <Link to="/browse/trending" className="see-more-link">
                      See All{" "}
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        color="white"
                        className="ml-1"
                      />
                    </Link>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="blog-item">
                  <div className="blog-img-sec">
                    <Link to="/browse/featured">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/blog-img-2.jpg"
                        }
                        alt="user-image"
                        className="blog-img"
                      />
                    </Link>
                  </div>
                  <div className="blog-details-sec">
                    <Link to="/browse/featured">
                      <h4 className="title">
                        Bring magical moments to the 9-5
                      </h4>
                    </Link>
                    <p className="desc">
                      Have Snoop Dogg endorse your brand for a digital ad, get
                      Troy Aikman to join your virtual event, have Bethenny
                      Frankel spice up your company meeting or internal comms.
                    </p>
                  </div>
                  <div className="blog-view-all-sec">
                    <Link to="/browse/featured" className="see-more-link">
                      See All{" "}
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        color="white"
                        className="ml-1"
                      />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HomeBlog);
