import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/Helper/NotificationMessage";
import {
  fetchCategoryFailure,
  fetchCategorySuccess,
  fetchOccasionSuccess,
  fetchSubCategoryFailure,
  fetchSubCategorySuccess,
  fetchOccasionFailure,
} from "../actions/LookUpAction";
import {
  FETCH_CATEGORY_START,
  FETCH_OCCASION_START,
  FETCH_SUB_CATEGORY_START,
} from "../actions/ActionConstant";

function* fetchCategoryAPI() {
  try {
    const response = yield api.postMethod("categories_index");
    if (response.data.success) {
      yield put(fetchCategorySuccess(response.data.data));
    } else {
      yield put(fetchCategoryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchCategoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSubCategoryAPI() {
  try {
    const inputData = yield select((state) => state.lookup.subCat.inputData);
    const response = yield api.postMethod("sub_categories_index", inputData);
    if (response.data.success) {
      yield put(fetchSubCategorySuccess(response.data.data));
    } else {
      yield put(fetchSubCategoryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSubCategoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchOccasionAPI() {
  try {
    const response = yield api.postMethod("occasions_index");
    if (response.data.success) {
      yield put(fetchOccasionSuccess(response.data.data));
    } else {
      yield put(fetchOccasionFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchOccasionFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_CATEGORY_START, fetchCategoryAPI)]);
  yield all([yield takeLatest(FETCH_SUB_CATEGORY_START, fetchSubCategoryAPI)]);
  yield all([yield takeLatest(FETCH_OCCASION_START, fetchOccasionAPI)]);
}
