import React, { useState, useEffect, Component, useRef } from "react";
import { connect } from "react-redux";
import "./RequestBookingIndex.css";
import { Form } from "react-bootstrap";
import RequestBookingCard from "./RequestBookingCard";
import { fetchSingleModelStart } from "../../store/actions/ModelAction";
import { fetchSingleSubscriptionStart } from "../../store/actions/SubscriptionAction";
import { fetchOccasionStart } from "../../store/actions/LookUpAction";
import { bookingSaveStart } from "../../store/actions/BookingAction";
import configuration from "react-global-configuration";

const RequestBookingIndex = (props) => {
  const [inComingData, setInComingData] = useState(false);
  const [inputData, setInputData] = useState({
    user_subscription_id: props.match.params.subscription_id,
    model_username: props.match.params.username,
  });

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevParams = usePrevious(props.match.params.username);

  useEffect(() => {
    props.dispatch(fetchOccasionStart());
  }, []);

  useEffect(() => {
    if (
      props.location.model !== undefined &&
      props.location.subscription !== undefined
    ) {
      setInComingData(true);
    } else {
      if (prevParams !== props.match.params.username) {
        props.dispatch(
          fetchSingleModelStart({
            username: props.match.params.username,
          })
        );
        props.dispatch(
          fetchSingleSubscriptionStart({
            user_subscription_id: props.match.params.subscription_id,
          })
        );
      }
    }
  }, [prevParams, props.match.params.username]);

  const handleSubmit = (event) => {
    event.preventDefault();

    props.dispatch(
      bookingSaveStart({
        ...inputData,
        model_id: props.singleModel.data.user.user_id,
      })
    );
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="request-booking-sec">
          <Form className="request-bread">
            <span>1. Account</span>
            <span className="active">2. Request</span>
            <span>3. Payment</span>
          </Form>
          {props.singleModel.loading ? (
            "Loading..."
          ) : (
            <RequestBookingCard
              inputData={inputData}
              setInputData={setInputData}
              singleModel={props.singleModel}
              occasion={props.occasion}
              handleSubmit={handleSubmit}
              saveBooking={props.saveBooking}
            />
          )}
          <small className="request-booking-bottom-text">
            By booking, you agree to {configuration.get("configData.site_name")}
            's
            <a href="/page/terms" target="_blank">
              Terms of Service
            </a>
            <span>
              {" "}
              and
              <a href="/page/privacy" target="_blank">
                Privacy Policy
              </a>
            </span>
          </small>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  singleModel: state.model.singleModel,
  occasion: state.lookup.occasion,
  saveBooking: state.bookings.saveBooking,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(RequestBookingIndex);
