import React from "react";
import { Modal, Media, Image } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import NoDataFound from "../NoDataFound/NoDataFound";
import configuration from "react-global-configuration";

const AllReviewModal = (props) => {
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.allReviewModal}
      onHide={props.handleCloseAllReviewModal}
      className="review-modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>Latest reviews</h4>
        </Modal.Title>
        <i
          className="fas fa-times close-icon"
          onClick={props.handleCloseAllReviewModal}
        ></i>
      </Modal.Header>
      <Modal.Body
        style={{ "max-height": "calc(100vh - 210px)", "overflow-y": "auto" }}
      >
        {props.modelRating.loading ? (
          "Loading..."
        ) : props.modelRating.data.model_ratings.length > 0 ? (
          props.modelRating.data.model_ratings.map((rating) => (
            <div className="review-modal-view-sec">
              <div className="rating-left-sec">
                <StarRatings
                  rating={rating.ratings_formatted}
                  starRatedColor="rgb(255, 214, 67)"
                  numberOfStars={5}
                  starDimension="20px"
                  starSpacing="0px"
                />
                <span className="rating-count">
                  {rating.ratings_formatted} stars
                </span>
              </div>
              <div className="review-info-sec">
                <p className="desc">{rating.review}</p>
                <h4 className="title">
                  By {rating.username} on {rating.updated_formatted}
                </h4>
                <p className="sub-desc" hidden>
                  Private {configuration.get("configData.site_name")} Video
                </p>
              </div>
            </div>
          ))
        ) : (
          <NoDataFound />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AllReviewModal;
