import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { Container, Row, Col } from "react-bootstrap";
import VideoPlayer from "react-video-js-player";

const HomeBanner = (props) => {
  useEffect(() => {}, []);
  return (
    <>
      <div className="home-banner-sec">
        <Container fluid>
          <Row>
            <Col md={12}>
              <div className="banner-video-sec">
                <VideoPlayer
                  controls={false}
                  src={configuration.get("configData.home_banner_video")}
                  poster="https://d3el26csp1xekx.cloudfront.net/miscellaneous/videos/useCaseOverlayWebthumbnail.jpg"
                  width="100%"
                  height="336px"
                  className="video-size"
                  autoplay={true}
                  muted={true}
                  hideControls={[
                    "play",
                    "volume",
                    "seekbar",
                    "timer",
                    "playbackrates",
                    "fullscreen",
                  ]}
                />
                {/* <video width="100%" height="100%" autoplay="" loop="" playsinline="" poster="https://d3el26csp1xekx.cloudfront.net/miscellaneous/videos/useCaseOverlayWebthumbnail.jpg" className="PcTW52zwprWLtuN-cvyoq video-sec">
              <source src="https://d3el26csp1xekx.cloudfront.net/miscellaneous/videos/useCaseOverlayWebApple.m4v" type="video/mp4"/>
              <source src="config.cloudfrontBaseUrl}/miscellaneous/videos/useCaseOverlayWebMp4.mp4" type="video/mp4"/>
            </video> */}
                <p className="banner-heading">
                  {configuration.get("configData.tag_name")}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HomeBanner);
