import React, { Component } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Button,
  Badge,
  Modal,
  Media,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Home.css";
import HomeFeatured from "./HomeFeatured";
import ModelSuggestionSec from "./ModelSuggestionSec";

class SingleActorView extends Component {
  state = {
    show: false,
    howitworksModal: false,
  };

  toggle = () =>
    this.setState((currentState) => ({ show: !currentState.show }));

  showHowItWorksModal = () => {
    this.setState({ howitworksModal: true });
  };

  closeHowItWorksModal = () => {
    this.setState({ howitworksModal: false });
  };

  render() {
    return (
      <>
        <div className="single-filter-view-sec">
          <div className="single-filter-view-header-sec">
            <Breadcrumb>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item>Musicians</Breadcrumb.Item>
              <Breadcrumb.Item active>Lead Singers</Breadcrumb.Item>
            </Breadcrumb>
            <div className="btn-action-sec">
              <Button className="btn-join">Join Fan Club</Button>
              <Button className="btn-copy">
                <Image
                  src={window.location.origin + "/assets/images/copy.svg"}
                  alt="user-image"
                  className="icons-img"
                />
              </Button>
            </div>
          </div>
          <div className="single-filter-view-body-sec">
            <div className="single-filter-profile-sec">
              <div className="profile-wrapper">
                <div className="profile-img-sec">
                  <Image
                    src={window.location.origin + "/assets/images/img-2.jpg"}
                    alt="user-image"
                    className="filter-profile-img"
                  />
                </div>
              </div>
              <div className="profile-details-sec">
                <h2 className="title">Nick Lachey</h2>
                <p className="sub-desc">Singer - 98 Degrees - TV Hos</p>
                <h4 className="desc">
                  Proceeds from these cameos will go to Feeding America
                </h4>
                <Badge className="feeding-badge">
                  <Image
                    src={window.location.origin + "/assets/images/featured.svg"}
                    alt="user-image"
                    className="flag-icon-img"
                  />
                  Feeding America
                </Badge>
              </div>
            </div>
            <div className="single-filter-review-sec">
              <div className="rating-left-sec">
                <ul className="list-unstyled rating-sec">
                  <Media as="li">
                    <i class="fas fa-star"></i>
                  </Media>
                  <Media as="li">
                    <i class="fas fa-star"></i>
                  </Media>
                  <Media as="li">
                    <i class="fas fa-star"></i>
                  </Media>
                  <Media as="li">
                    <i class="fas fa-star"></i>
                  </Media>
                  <Media as="li">
                    <i class="fas fa-star"></i>
                  </Media>
                  <span className="rating-count">5.0</span>
                </ul>
                <Link to="#" className="see-all-link">
                  See All 323 reviews
                </Link>
              </div>
              <div className="review-info-sec">
                <h4 className="title">
                  Reviewed by D***** on December 22nd, 2020
                </h4>
                <p className="desc">
                  <span id="display_1">
                    Absolutely amazing! Thank you Nick for the fabulous cameo!
                    My sister flipped out & she thanks you so much! Happy
                    Holidays to you & ...
                  </span>
                  {this.state.show && (
                    <span id="display_1">
                      Absolutely amazing! Thank you Nick for the fabulous cameo!
                      My sister flipped out & she thanks you so much! Happy
                      Holidays to you & your family! See in 2021!
                    </span>
                  )}
                  <Link to="#" className="more-link" onClick={this.toggle}>
                    {" "}
                    more
                  </Link>
                </p>
              </div>
            </div>
            <div className="request-btn-sec">
              <Link to="/request-booking">
                <Button className="btn-request">Request $75</Button>
              </Link>
              <Link to="/text-me">
                <Button className="btn-chat">Chat $75</Button>
              </Link>
              <Link to="#">
                <Button
                  className="btn-how-it-works"
                  onClick={this.showHowItWorksModal}
                >
                  <Image
                    src={
                      window.location.origin + "/assets/images/info-icon.svg"
                    }
                    alt="user-image"
                    className="info-icon"
                  />
                  How does it work?
                </Button>
              </Link>
            </div>
            <div className="video-post-sec">
              <Row>
                <Col md={4} className="resp-mrg-btm-xs">
                  <div className="video-post-item">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-2.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-post-bottom-sec">
                      <div className="video-user-img-sec">
                        <div className="video-user-top-flex">
                          <div className="avatar-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/avatar-img.jpg"
                              }
                              alt="user-image"
                              className="avatar-img"
                            />
                          </div>
                          <div className="avatar-img-1">
                            <span>RH</span>
                          </div>
                        </div>
                        <div className="play-icon-sec">
                          <i class="fas fa-play"></i>
                        </div>
                      </div>
                      <div className="video-tag-sec">
                        <Badge className="badge-tag">#pepTalk</Badge>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} className="resp-mrg-btm-xs">
                  <div className="video-post-item">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-3.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-post-bottom-sec">
                      <div className="video-user-img-sec">
                        <div className="video-user-top-flex">
                          <div className="avatar-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/avatar-img.jpg"
                              }
                              alt="user-image"
                              className="avatar-img"
                            />
                          </div>
                          <div className="avatar-img-1">
                            <span>RH</span>
                          </div>
                        </div>
                        <div className="play-icon-sec">
                          <i class="fas fa-play"></i>
                        </div>
                      </div>
                      <div className="video-tag-sec">
                        <Badge className="badge-tag">#pepTalk</Badge>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="video-post-item">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-4.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-post-bottom-sec">
                      <div className="video-user-img-sec">
                        <div className="video-user-top-flex">
                          <div className="avatar-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/avatar-img.jpg"
                              }
                              alt="user-image"
                              className="avatar-img"
                            />
                          </div>
                          <div className="avatar-img-1">
                            <span>RH</span>
                          </div>
                        </div>
                        <div className="play-icon-sec">
                          <i class="fas fa-play"></i>
                        </div>
                      </div>
                      <div className="video-tag-sec">
                        <Badge className="badge-tag">#pepTalk</Badge>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="space-mg"></div>
              <Row>
                <Col md={4} className="resp-mrg-btm-xs">
                  <div className="video-post-item">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-5.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-post-bottom-sec">
                      <div className="video-user-img-sec">
                        <div className="video-user-top-flex">
                          <div className="avatar-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/avatar-img.jpg"
                              }
                              alt="user-image"
                              className="avatar-img"
                            />
                          </div>
                          <div className="avatar-img-1">
                            <span>RH</span>
                          </div>
                        </div>
                        <div className="play-icon-sec">
                          <i class="fas fa-play"></i>
                        </div>
                      </div>
                      <div className="video-tag-sec">
                        <Badge className="badge-tag">#pepTalk</Badge>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} className="resp-mrg-btm-xs">
                  <div className="video-post-item">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-6.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-post-bottom-sec">
                      <div className="video-user-img-sec">
                        <div className="video-user-top-flex">
                          <div className="avatar-img">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/avatar-img.jpg"
                              }
                              alt="user-image"
                              className="avatar-img"
                            />
                          </div>
                          <div className="avatar-img-1">
                            <span>RH</span>
                          </div>
                        </div>
                        <div className="play-icon-sec">
                          <i class="fas fa-play"></i>
                        </div>
                      </div>
                      <div className="video-tag-sec">
                        <Badge className="badge-tag">#pepTalk</Badge>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="video-post-item before-overlay">
                    <div className="video-post-img-sec">
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/video-img-5.jpg"
                        }
                        alt="user-image"
                        className="video-post-img"
                      />
                    </div>
                    <div className="video-last-join-sec">
                      <h5 className="title">Want more?</h5>
                      <p className="desc">
                        Join Emily Kuroda's Fan Club to unlock even more
                        content.
                      </p>
                      <Link to="#">
                        <Button className="btn-join-secondary">
                          Join Fans Club
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="single-filter-review-sec">
              <div className="rating-left-sec">
                <p className="see-all-link">Typically responds in</p>
                <h5 className="desc">
                  <Image
                    src={window.location.origin + "/assets/images/zap.svg"}
                    alt="user-image"
                    className="zap-icon"
                  />
                  5 days
                </h5>
              </div>
              <div className="review-info-sec">
                <div className="tag-wrapper-sec">
                  <span className="tag-info">
                    <Link to="#">#Featured</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#Lead Singers</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#More</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#Musicians</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#Reality TV</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#Singer/Songwriter</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#TV Hosts</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#For Charity</Link>
                  </span>
                  <span className="tag-info">
                    <Link to="#">#New and Noteworthy</Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="single-filter-footer-sec">
              <Row>
                <Col md={12} lg={6} xl={6}>
                  <div className="single-filter-about-sec">
                    <h4 className="title">
                      What is {configuration.get("configData.site_name")}?
                    </h4>
                    <Row>
                      <Col md={4} className="resp-mrg-btm-xs">
                        <div className="about-item">
                          <div className="icon-sec">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/mobile-icon.svg"
                              }
                              alt="user-image"
                              className="about-icons"
                            />
                          </div>
                          <h4 className="desc">Send your request</h4>
                        </div>
                      </Col>
                      <Col md={4} className="resp-mrg-btm-xs">
                        <div className="about-item">
                          <div className="icon-sec">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/video-icon.svg"
                              }
                              alt="user-image"
                              className="about-icons"
                            />
                          </div>
                          <h4 className="desc">Get your video</h4>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="about-item">
                          <div className="icon-sec">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/thumsup.svg"
                              }
                              alt="user-image"
                              className="about-icons"
                            />
                          </div>
                          <h4 className="desc">Make their year</h4>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={12} lg={6} xl={6}>
                  <div className="single-filter-about-sec">
                    <h4 className="title-1">
                      What does a good request look like??
                    </h4>
                    <h5 className="sub-title">Tip #1</h5>
                    <p className="desc">
                      Try to be as specific as possible with your request such
                      as your relationship to the Cameo recipient, numbers &
                      details. Ex. "tell my BFF Cam congrats on graduating from
                      UCLA."
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="single-filter-footer-sec">
              <ModelSuggestionSec />
            </div>
          </div>
        </div>

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.howitworksModal}
          handleClose={this.closeHowItWorksModal}
          className="review-modal time-line-modal"
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <h4>What happens when I request a Cameo?</h4>
            </Modal.Title>
            <i
              className="fas fa-times close-icon"
              onClick={this.closeHowItWorksModal}
            ></i>
          </Modal.Header>
          <Modal.Body>
            <div className="howitswork-wrapper">
              <div className="howitswork-row">
                <div className="howitswork-icon">
                  <Image
                    src={window.location.origin + "/assets/images/calendar.svg"}
                    alt="user-image"
                    className="time-line-img"
                  />
                </div>
                <p className="time-line-desc">
                  Your request will be completed within 7 days
                </p>
              </div>
              <div className="howitswork-row">
                <div className="howitswork-icon">
                  <Image
                    src={window.location.origin + "/assets/images/document.svg"}
                    alt="user-image"
                    className="time-line-img"
                  />
                </div>
                <p className="time-line-desc">
                  Your receipt and order updates will be sent to the email
                  provided under Delivery Information
                </p>
              </div>
              <div className="howitswork-row">
                <div className="howitswork-icon">
                  <Image
                    src={
                      window.location.origin + "/assets/images/envelop-pink.svg"
                    }
                    alt="user-image"
                    className="time-line-img"
                  />
                </div>
                <p className="time-line-desc">
                  When your request is completed, we’ll email and text you a
                  link to view, share, or download your Cameo
                </p>
              </div>
              <div className="howitswork-row">
                <div className="howitswork-icon no-after">
                  <Image
                    src={
                      window.location.origin +
                      "/assets/images/credit-card-pink.svg"
                    }
                    alt="user-image"
                    className="time-line-img"
                  />
                </div>
                <p className="time-line-desc">
                  If for some reason your video isn’t completed, the hold on
                  your card will be removed within 5-7 business days
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleActorView);
